import gql from 'graphql-tag'

export const PAGE_TREE_ORDER_SAVE = gql`
    mutation pageTreeReorder($input: [OrderItemInput!]!) {
        pageTreeReorder(input: $input) {
            success
            statusCode
        }
    }
`
