import { Delete } from '@mui/icons-material'
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import ImageUpload from 'components/ImageUpload/ImageUpload'
import { useState } from 'react'
import { FileError, FileRejection } from 'react-dropzone'
import { FileUpload } from 'utils/types'

enum UploadFileError {
    FILE_INVALID_TYPE = 'file-invalid-type',
    FILE_TOO_LARGE = 'file-too-large',
    TOO_MANY_FILES = 'too-many-files',
}

export interface AssetsUploadPanelProps {
    onCancel: () => void
    onSubmit: (files: FileUpload[], shouldImagesResized: boolean) => void
}

const renderUploadItem = (file: FileUpload, index: number, deleteFunc: (index: number) => void): JSX.Element => {
    let img = '/img/file.png'
    if (file.type === 'application/pdf') {
        img = '/img/pdf.png'
    } else if (file.type.startsWith('image')) {
        img = file.base64Data
    }

    return (
        <Card key={index} sx={{ display: 'flex' }}>
            <CardMedia component="img" sx={{ width: 151 }} image={img} />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" style={{ width: 200 }}>
                        {file.name}
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                    <Tooltip title="Delete">
                        <IconButton
                            onClick={() => {
                                deleteFunc(index)
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Card>
    )
}

const AssetsUploadPanel = (props: AssetsUploadPanelProps): JSX.Element => {
    const { onCancel, onSubmit } = props

    const [uploadedFiles, setUploadedFiles] = useState<FileUpload[]>([])
    const [errorUploads, setErrorUploads] = useState<string[]>([])
    const [shouldImagesResized, setShouldImagesResized] = useState<boolean>(false)

    const onUpload = () => {
        onSubmit(uploadedFiles, shouldImagesResized)
    }

    const onDeleteFile = (index: number): void => {
        const newUploads = [...uploadedFiles]
        newUploads.splice(index, 1)
        setUploadedFiles(newUploads)
    }

    const onImageFileChosen = (files: File[]) => {
        const handleChange =
            (f: File) =>
            (e: ProgressEvent<FileReader>): void => {
                setUploadedFiles((uploadedFiles) => [
                    ...uploadedFiles,
                    {
                        name: f.name,
                        base64Data: e.target?.result as string,
                        type: f.type,
                    },
                ])
            }
        files.forEach((file: File) => {
            const reader = new FileReader()
            reader.onload = handleChange(file)
            reader.readAsDataURL(file)
        })
    }

    const onFileReject = (rej: FileRejection[]) => {
        const errorsToState: string[] = []
        rej.forEach((fileRejection: FileRejection) => {
            fileRejection.errors.forEach((fileRejectionError: FileError) => {
                if (fileRejectionError.code === UploadFileError.FILE_TOO_LARGE)
                    errorsToState.push(`${fileRejection.file.name} is too large`)
                if (fileRejectionError.code === UploadFileError.FILE_INVALID_TYPE)
                    errorsToState.push(`${fileRejection.file.name} is invalid type`)
            })
        })
        setErrorUploads(errorsToState)
    }

    return (
        <Box display="flex" flexDirection="column" p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">Upload Asset</Typography>
            </Grid>
            <Grid container mb={2}>
                <ImageUpload onImageFileChosen={onImageFileChosen} onFileReject={onFileReject} />
            </Grid>
            <Stack sx={{ width: '100%' }} spacing={2} mb={2}>
                {uploadedFiles.map((file: FileUpload, index: number) => renderUploadItem(file, index, onDeleteFile))}
            </Stack>
            <Stack sx={{ width: '100%' }} spacing={2} mb={2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={shouldImagesResized}
                            onChange={() => setShouldImagesResized(!shouldImagesResized)}
                        />
                    }
                    label="Downscale images by 50%?"
                />
            </Stack>
            <Stack sx={{ width: '100%' }} spacing={2} mb={2}>
                {errorUploads.map((error: string, index: number) => (
                    <Alert
                        key={index}
                        severity="error"
                        onClose={() => {
                            const newErrorUploads = [...errorUploads]
                            newErrorUploads.splice(index, 1)
                            setErrorUploads(newErrorUploads)
                        }}
                    >
                        {error}
                    </Alert>
                ))}
            </Stack>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={onUpload}>
                    Save
                </Button>
            </Stack>
        </Box>
    )
}

export default AssetsUploadPanel
