import gql from 'graphql-tag'

export const DEACTIVATE_PAGE = gql`
    mutation deactivatePage($pageID: ID!) {
        deactivatePage(pageID: $pageID) {
            statusCode
            success
        }
    }
`
