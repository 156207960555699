import gql from 'graphql-tag'

export const DELETE_PAGE = gql`
    mutation deletePage($pageID: ID!) {
        deletePage(pageID: $pageID) {
            statusCode
            success
        }
    }
`
