import { Autocomplete, Box, Button, Chip, Grid, Stack, TextField, Typography } from '@mui/material'
import fileSize from 'filesize'
import { Asset, AssetType } from 'graphql/types'
import { Controller, useForm } from 'react-hook-form'

export interface AssetDetailsPanelProps {
    asset: Asset
    tagList: string[]
    onCancel: () => void
    onSubmit: (title: string, tags: string[]) => void
}

interface AssetDetailsPanelInputs {
    Title: string
    Tags: string[]
}

const AssetDetailsPanel = (props: AssetDetailsPanelProps): JSX.Element => {
    const { asset, tagList, onCancel, onSubmit } = props
    const { handleSubmit, control } = useForm<AssetDetailsPanelInputs>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            Title: asset.name,
            Tags: asset.tags ?? [],
        },
    })

    const handleOnSave = () => {
        handleSubmit((data: AssetDetailsPanelInputs) => {
            onSubmit(data.Title, data.Tags)
        })()
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">{asset.name}</Typography>
            </Grid>
            <Grid container spacing={2}>
                {asset.type === AssetType.IMAGE && (
                    <Grid
                        item
                        alignItems={'center'}
                        alignContent={'center'}
                        justifyContent={'center'}
                        style={{ width: '100%', maxHeight: '400px', textAlign: 'center' }}
                        xs={12}
                    >
                        <a href={asset.url} target="_blank" rel="noreferrer">
                            <img src={asset.thumbnailUrl ?? ''} style={{ maxWidth: '100%', maxHeight: '400px' }} />
                        </a>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="body1"> ID: {asset.id.replace('Asset/', '')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1"> Size: {fileSize(asset.sizeBytes)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">Uploaded: {asset.createdAt} </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">Uploaded by: {asset.createdBy} </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="Title"
                        control={control}
                        rules={{
                            required: 'Please enter a name for the folder',
                            maxLength: { value: 150, message: 'The order name may only be 150 characters long.' },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Title"
                                variant="filled"
                                inputProps={{ maxLength: 150 }}
                                fullWidth={true}
                                {...field}
                                helperText={fieldState.error?.message ?? ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} mb={3}>
                    <Controller
                        name="Tags"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                multiple
                                fullWidth
                                id="Tags"
                                defaultValue={asset.tags ?? []}
                                options={tagList}
                                freeSolo
                                renderTags={(value: readonly string[], getTagProps) =>
                                    value.map((option: string, index: number) => (
                                        // eslint-disable-next-line react/jsx-key
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                onChange={(event, value) => {
                                    field.onChange(value)
                                }}
                                renderInput={(params) => <TextField {...params} variant="filled" label="Tags" />}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleOnSave}>
                    Save
                </Button>
            </Stack>
        </Box>
    )
}

export default AssetDetailsPanel
