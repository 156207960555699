import { Grid, Typography } from '@mui/material'
import { useStoreState } from 'store/hooks'
import colors from 'utils/colors'
import { resolvePagelURL } from 'utils/linkResolver'

const MultiEditPageInfo = (): JSX.Element => {
    const selectedFile = useStoreState((state) => state.multiPageEditModel.selectedFile)
    const selectedPages = useStoreState((state) => state.multiPageEditModel.selectedPages)
    const isError = useStoreState((state) => state.multiPageEditModel.isError)
    const messages = useStoreState((state) => state.multiPageEditModel.messages)
    const pageList = useStoreState((state) => state.model.pageList)

    return (
        <Grid container>
            <Grid item xs={12} mt={2}>
                {selectedFile && (
                    <>
                        <Typography variant="h6">File</Typography>
                        <Typography variant="body1">{selectedFile?.name}</Typography>
                    </>
                )}
                {selectedPages.length > 0 && (
                    <>
                        <Typography variant="h6" marginTop={2}>
                            Seiten
                        </Typography>
                        {selectedPages.map((page) => (
                            <Typography
                                key={page}
                                variant="body1"
                                style={{
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    overflowWrap: 'break-word',
                                }}
                            >
                                {resolvePagelURL(page, pageList, false)}
                            </Typography>
                        ))}
                    </>
                )}
                <Typography variant="h6" marginTop={4}>
                    Status
                </Typography>
                {messages.map((message, index) => (
                    <Typography key={index} variant="body1" color={isError ? colors.warning : colors.success}>
                        {message}
                    </Typography>
                ))}
            </Grid>
            <Grid container direction={'column'} justifyContent={'space-between'} alignItems={'left'} mb={2}></Grid>
        </Grid>
    )
}

export default MultiEditPageInfo
