import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { checkVersionDaemon } from 'utils/checkVersionDaemon'

export interface BlockSettingsEditPanelProps {
    onCancel: () => void
    onSubmit: (name: string, identifier: string) => void
    name: string
    identifier: string
    saveIsDisabled: boolean
}

interface BlockSettingsEditInputs {
    Name: string
    Identifier: string
}

const BlockSettingsEditPanel = (props: BlockSettingsEditPanelProps): JSX.Element => {
    const { onCancel, onSubmit, name, identifier, saveIsDisabled } = props

    //initalise deamon to check version
    checkVersionDaemon()

    const { handleSubmit, control } = useForm<BlockSettingsEditInputs>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            Name: name,
            Identifier: identifier,
        },
    })

    const handleOnSave = () => {
        handleSubmit((data: BlockSettingsEditInputs) => {
            onSubmit(data.Name, data.Identifier)
        })()
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">Block Settings</Typography>
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="Name"
                    control={control}
                    rules={{
                        required: 'Please enter a name',
                        maxLength: { value: 150, message: 'The name may only be 150 characters long' },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Name"
                            variant={'filled'}
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>

            <Grid container mb={2}>
                <Controller
                    name="Identifier"
                    control={control}
                    rules={{
                        maxLength: { value: 150, message: 'The identifier may only be 150 characters long' },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Identifier"
                            variant={'filled'}
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleOnSave} disabled={saveIsDisabled}>
                    Save
                </Button>
            </Stack>
        </Box>
    )
}

export default BlockSettingsEditPanel
