import { Alert, Snackbar } from '@mui/material'
import Slide, { SlideProps } from '@mui/material/Slide'

type TransitionProps = Omit<SlideProps, 'direction'>

export enum SnackbarType {
    WARNING = 'warning',
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
}

export interface SnackbarProps {
    message: string
    isOpen: boolean
    type: SnackbarType
    onClose: () => void
}

function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />
}

const GeneralSnackbar = (props: SnackbarProps) => {
    const { onClose, message, isOpen, type } = props

    return (
        <Snackbar open={isOpen} TransitionComponent={TransitionDown} onClose={onClose}>
            <Alert onClose={onClose} severity={type} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default GeneralSnackbar
