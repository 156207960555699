import gql from 'graphql-tag'

export const IMPORT_PAGE = gql`
    mutation importPage($input: ImportDataInput!) {
        importPage(input: $input) {
            success
            message
            block {
                id
                identifier
                createdAt
                deletedAt
                currentVersion {
                    id
                    name
                    state
                    contents
                    updatedBy {
                        id
                        name
                    }
                    createdAt
                    updatedAt
                }
                historyVersions {
                    name
                    id
                    blockID
                    state
                    contents
                    createdAt
                    updatedAt
                    updatedBy {
                        id
                        name
                    }
                }
            }
            page {
                id
                parentPageIdentifier
                sortIndex
                deactivated
                isTemplate
                createdAt
                updatedAt
                currentVersion {
                    ID
                    pageID
                    state
                    contents
                    createdAt
                    updatedAt
                    updatedBy {
                        id
                        name
                    }
                    pageSettings {
                        htmlTitle
                        urlTitle
                        metaDescription
                        metaKeywords
                        robotsOption
                        includeInSitemapXML
                        pageRank
                        changeFrequency
                        language
                        translationID
                    }
                }
                historyVersions {
                    ID
                    pageID
                    state
                    contents
                    createdAt
                    updatedAt
                    updatedBy {
                        id
                        name
                    }
                    pageSettings {
                        htmlTitle
                        urlTitle
                        metaDescription
                        metaKeywords
                        robotsOption
                        includeInSitemapXML
                        pageRank
                        changeFrequency
                        language
                        translationID
                    }
                }
            }
        }
    }
`
