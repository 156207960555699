import React from 'react'
import Typography from '@mui/material/Typography'
import { NodeModel } from '@minoru/react-dnd-treeview'
import { CustomData } from './types'
import styles from './CustomNode.module.css'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

type Props = {
    node: NodeModel<CustomData>
    depth: number
    isOpen: boolean
    onToggle: (id: NodeModel['id']) => void
}

export const CustomNode: React.FC<Props> = (props) => {
    const { droppable } = props.node
    const indent = props.depth * 24

    const handleToggle = (e: React.MouseEvent) => {
        e.stopPropagation()
        props.onToggle(props.node.id)
    }

    return (
        <div className={`tree-node ${styles.root}`} style={{ paddingInlineStart: indent }}>
            <div className={`${styles.expandIconWrapper} ${props.isOpen ? styles.isOpen : ''}`}>
                {droppable && (
                    <div onClick={handleToggle}>
                        <ChevronRightIcon />
                    </div>
                )}
            </div>
            <div className={styles.labelGridItem}>
                <Typography variant="body1">{`${props.node.text}`}</Typography>
            </div>
        </div>
    )
}
