import MenuIcon from '@mui/icons-material/Menu'
import PublishIcon from '@mui/icons-material/Publish'
import SettingsIcon from '@mui/icons-material/Settings'
import { Badge } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { AssetsViewMode } from 'pages/AssetManagerPage/AssetManagerPage'
import { BlockListViewMode } from 'pages/BlockListPage/BlockListPage'
import { PageTreeViewMode } from 'pages/PageTreePage/PageTreePage'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import colors from 'utils/colors'
import { logout } from 'utils/logout'
import { NavigationRoutes } from 'utils/navigationRoutes'
import { UnpublishedItem } from 'utils/types'

export enum CMSTab {
    PAGES,
    ASSETS,
    DATA,
    TEMPLATE,
    SETTINGS,
    PUBLISH,
}

const pages = [
    { name: 'Pages', type: CMSTab.PAGES },
    { name: 'Blocks', type: CMSTab.TEMPLATE },
    { name: 'Assets', type: CMSTab.ASSETS },
    { name: 'Settings', type: CMSTab.SETTINGS, component: 'ICON' },
    { name: 'Publish', type: CMSTab.PUBLISH, component: 'ICON' },
]

const ProfileClick = () => {
    console.log('onProfileClick')
}

const LogoutClick = async () => {
    const token = document.cookie
        .split('; ')
        .find((row) => row.startsWith('token='))
        ?.split('=')[1]
    if (token) {
        await logout(token)
    }
    // call api then reroute alright
}

const settings = [
    { name: 'Profile', action: ProfileClick },
    { name: 'Logout', action: LogoutClick },
]

export interface CMSHeaderProps {
    selectedTab: CMSTab
    onClickPublish: () => void
    onClickSettings: () => void
    unpublishedItems: UnpublishedItem[]
    viewMode: PageTreeViewMode | BlockListViewMode | AssetsViewMode | undefined
}

const iconBtnHeader = { my: 2, color: 'black' }

const CMSHeader = (props: CMSHeaderProps) => {
    const { selectedTab, onClickPublish, onClickSettings, viewMode } = props
    const navigate = useNavigate()
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const tabClicked = (type: CMSTab) => {
        if (type === CMSTab.PAGES) navigate(NavigationRoutes.PAGE_TREE)
        else if (type === CMSTab.TEMPLATE) navigate(NavigationRoutes.BLOCK_LIST)
        else if (type === CMSTab.ASSETS) navigate(NavigationRoutes.ASSETS)
        else if (type === CMSTab.PUBLISH) onClickPublish()
        else if (type === CMSTab.SETTINGS) onClickSettings()
    }

    return (
        <>
            <AppBar position="fixed" sx={{ backgroundColor: 'white' }}>
                <Toolbar>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            maxWidth: 120,
                            paddingRight: 2,
                        }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/qubiduLogo.svg'}
                            style={{ maxWidth: 120 }}
                            alt={'Qubidu'}
                            loading="lazy"
                        />
                    </Box>

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <Tooltip title="Open navigation">
                            <IconButton size="large" onClick={handleOpenNavMenu} sx={{ color: 'black' }}>
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <Tooltip
                                    placement="right"
                                    key={page.name}
                                    title={
                                        page.component === 'ICON' && page.name === 'Settings'
                                            ? 'Settings'
                                            : page.component === 'ICON' && page.name === 'Publish'
                                            ? 'Publish'
                                            : ''
                                    }
                                >
                                    <MenuItem onClick={() => tabClicked(page.type)} sx={{ justifyContent: 'center' }}>
                                        {page.component === undefined && (
                                            <Typography
                                                color={page.type === selectedTab ? 'secondary' : ''}
                                                textAlign="center"
                                            >
                                                {page.name}
                                            </Typography>
                                        )}
                                        {page.component === 'ICON' && page.name === 'Settings' && (
                                            <SettingsIcon
                                                sx={{
                                                    color:
                                                        viewMode === PageTreeViewMode.SETTINGS ||
                                                        viewMode === BlockListViewMode.SETTINGS ||
                                                        viewMode === AssetsViewMode.SETTINGS
                                                            ? colors.primary
                                                            : colors.black,
                                                }}
                                            />
                                        )}
                                        {page.component === 'ICON' && page.name === 'Publish' && (
                                            <Badge
                                                badgeContent={props.unpublishedItems.length}
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        color:
                                                            viewMode === PageTreeViewMode.PUBLISH ||
                                                            viewMode === BlockListViewMode.PUBLISH ||
                                                            viewMode === AssetsViewMode.PUBLISH
                                                                ? colors.white
                                                                : colors.black,
                                                        backgroundColor:
                                                            viewMode === PageTreeViewMode.PUBLISH ||
                                                            viewMode === BlockListViewMode.PUBLISH ||
                                                            viewMode === AssetsViewMode.PUBLISH
                                                                ? colors.primary
                                                                : colors.secondary,
                                                    },
                                                }}
                                            >
                                                <PublishIcon
                                                    sx={{
                                                        color:
                                                            viewMode === PageTreeViewMode.PUBLISH ||
                                                            viewMode === BlockListViewMode.PUBLISH ||
                                                            viewMode === AssetsViewMode.PUBLISH
                                                                ? colors.primary
                                                                : colors.black,
                                                    }}
                                                />
                                            </Badge>
                                        )}
                                    </MenuItem>
                                </Tooltip>
                            ))}
                        </Menu>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/qubiduLogo.svg'}
                            style={{ maxWidth: 90 }}
                            alt={'Qubidu'}
                            loading="lazy"
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <React.Fragment key={page.name}>
                                {page.component === undefined && (
                                    <Button
                                        key={page.name}
                                        onClick={() => tabClicked(page.type)}
                                        sx={{
                                            my: 2,
                                            color: page.type === selectedTab ? '#5eafc7' : 'black',
                                            display: 'block',
                                        }}
                                    >
                                        <Typography textAlign="center" color="inherit" fontWeight={700}>
                                            {page.name}
                                        </Typography>
                                    </Button>
                                )}
                                {page.component === 'ICON' && page.name === 'Settings' && (
                                    <Tooltip title="Settings">
                                        <IconButton
                                            color="inherit"
                                            sx={iconBtnHeader}
                                            component="div"
                                            onClick={onClickSettings}
                                        >
                                            <SettingsIcon
                                                sx={{
                                                    color:
                                                        viewMode === PageTreeViewMode.SETTINGS ||
                                                        viewMode === BlockListViewMode.SETTINGS ||
                                                        viewMode === AssetsViewMode.SETTINGS
                                                            ? colors.primary
                                                            : colors.black,
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {page.component === 'ICON' && page.name === 'Publish' && (
                                    <Tooltip title="Publish">
                                        <IconButton
                                            color="inherit"
                                            sx={iconBtnHeader}
                                            component="div"
                                            onClick={onClickPublish}
                                        >
                                            <Badge
                                                badgeContent={props.unpublishedItems.length}
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        color:
                                                            viewMode === PageTreeViewMode.PUBLISH ||
                                                            viewMode === BlockListViewMode.PUBLISH ||
                                                            viewMode === AssetsViewMode.PUBLISH
                                                                ? colors.white
                                                                : colors.black,
                                                        backgroundColor:
                                                            viewMode === PageTreeViewMode.PUBLISH ||
                                                            viewMode === BlockListViewMode.PUBLISH ||
                                                            viewMode === AssetsViewMode.PUBLISH
                                                                ? colors.primary
                                                                : colors.secondary,
                                                    },
                                                }}
                                            >
                                                <PublishIcon
                                                    sx={{
                                                        color:
                                                            viewMode === PageTreeViewMode.PUBLISH ||
                                                            viewMode === BlockListViewMode.PUBLISH ||
                                                            viewMode === AssetsViewMode.PUBLISH
                                                                ? colors.primary
                                                                : colors.black,
                                                    }}
                                                />
                                            </Badge>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </React.Fragment>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center" onClick={setting.action}>
                                        {setting.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <div style={{ height: '72px' }}></div>
        </>
    )
}

export default CMSHeader
