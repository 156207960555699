import { Backdrop, CircularProgress } from '@mui/material'

export interface LoadingSpinnerProps {
    loading: boolean
}

const LoadingSpinner = (props: LoadingSpinnerProps): JSX.Element => {
    const { loading } = props

    return (
        <Backdrop className={'LoadingSpinner'} open={loading} style={{ zIndex: 99999 }}>
            <CircularProgress className={'LoadingSpinner'} />
        </Backdrop>
    )
}

export default LoadingSpinner
