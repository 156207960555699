import gql from 'graphql-tag'

export const FOLDER_UPDATE = gql`
    mutation folderUpdate($input: FolderUpdateInput!) {
        folderUpdate(input: $input) {
            id
            websiteID
            name
            tags
            parentFolder
            createdAt
        }
    }
`
