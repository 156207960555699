import { Box, CircularProgress } from '@mui/material'
import { default as CMSHeader, CMSTab } from 'components/CMSHeader/CMSHeader'
import DrawerGrid from 'components/DrawerGrid'
import { DrawerWidth } from 'components/DrawerGrid/DrawerGrid'
import ExportImportDialog from 'components/ExportImportDialog/ExportImportDialog'
import PagePreview from 'components/PagePreview/PagePreview'
import BlockCreatePanel from 'drawerPanels/BlockCreatePanel/BlockCreatePanel'
import BlockDetailsPanel from 'drawerPanels/BlockDetailsPanel/BlockDetailsPanel'
import BlockSettingsEditPanel from 'drawerPanels/BlockSettingsEditPanel/BlockSettingsEditPanel'
import BlockTreePanel from 'drawerPanels/BlockTreePanel/BlockTreePanel'
import PublishPanel from 'drawerPanels/PublishPanel/PublishPanel'
import SettingsSelectionPanel, {
    SettingsSelectionPanelNavigation,
} from 'drawerPanels/SettingsSelectionPanel/SettingsSelectionPanel'
import { ImportType, PublishItem } from 'graphql/types'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'
import { getParsedPageElements } from 'utils/parseElementContent'
import { EditingDataType } from 'utils/types'

export enum BlockListViewMode {
    PUBLISH = 'PUBLISH',
    SETTINGS = 'SETTINGS',
    BLOCKLIST = 'BLOCKLIST',
    ADD = 'ADD',
    EDIT = 'EDIT',
}

const BlockListPage = (): JSX.Element => {
    //data
    const elements: any = {}

    //hooks and data from store
    const navigate = useNavigate()

    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const currentlySelectedBlock = useStoreState((state) => state.model.currentlySelectedBlock)
    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)
    const blockList = useStoreState((state) => state.model.blockList)
    const blockData = useStoreState((state) => state.model.blockData)
    const loggedInUser = useStoreState((state) => state.model.loggedInUser)
    const assetsSimplified = useStoreState((state) => state.model.assetsSimplified)
    const savingCurrentVersionDisabled = useStoreState((state) => state.model.savingCurrentVersionDisabled)

    const startEditingPage = useStoreActions((actions) => actions.model.startEditingPage)
    const loadCMSData = useStoreActions((actions) => actions.model.loadCMSData)
    const loadBlock = useStoreActions((actions) => actions.model.loadBlock)
    const deleteBlock = useStoreActions((actions) => actions.model.deleteBlock)
    const duplicateBlock = useStoreActions((actions) => actions.model.duplicateBlock)
    const createBlock = useStoreActions((actions) => actions.model.createBlock)
    const saveBlock = useStoreActions((actions) => actions.model.saveBlock)
    const publishItems = useStoreActions((actions) => actions.model.publishItems)
    const changeDialogState = useStoreActions((actions) => actions.importModel.changeDialogState)
    const [navigation, setNavigation] = useState<SettingsSelectionPanelNavigation>()

    //internal state
    const [viewMode, setViewMode] = useState<BlockListViewMode>(BlockListViewMode.BLOCKLIST)

    //functions
    const selectBlock = (id: string) => {
        if (currentlySelectedBlock === undefined || (currentlySelectedBlock && currentlySelectedBlock.id !== id)) {
            loadBlock(id)
        }
    }

    const onDeleteBlock = (id: string) => {
        if (confirm('Are you sure you want to delete this block?')) {
            deleteBlock(id)
        }
    }

    const onDuplicateBlock = (id: string) => {
        duplicateBlock(id)
    }

    const onSaveBlocK = (name: string, identifier: string) => {
        if (currentlySelectedBlock)
            saveBlock({
                blockID: currentlySelectedBlock.id,
                name,
                content: currentlySelectedBlock.currentVersion.contents,
                identifier,
            })
        setViewMode(BlockListViewMode.BLOCKLIST)
    }

    const onEditBlock = () => {
        if (currentlySelectedBlock) {
            startEditingPage({
                currentlyEditingData: {
                    id: currentlySelectedBlock.id,
                    identifier: currentlySelectedBlock.identifier,
                    name: currentlySelectedBlock.currentVersion.name,
                    contents: currentlySelectedBlock.currentVersion.contents,
                    type: EditingDataType.BLOCK,
                },
                pageElementContents: getParsedPageElements(currentlySelectedBlock),
            })
            navigate('/PageEdit')
        }
    }

    // ------------------------------------------------------------------------- Load CMS Data
    useEffect(() => {
        if (!loggedInUser) {
            loadCMSData()
        }
    }, [])

    // ----------------------------------------------------------------------- Loading until webiste and cms data are loaded
    if (!selectedWebsite || !loggedInUser) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        )
    }

    elements['primary'] = (
        <BlockTreePanel
            blocks={blockList}
            onSelectBlock={selectBlock}
            onBlockCreate={() => setViewMode(BlockListViewMode.ADD)}
            selectedBlock={currentlySelectedBlock ? currentlySelectedBlock.id : ''}
        />
    )

    if (viewMode === BlockListViewMode.BLOCKLIST) {
        // TODO: the way I did this is a quick hack. I am not sure what the best way is - if a page has only 5 different
        // view modes then I guess putting them all into one file is ok. There is also something to watch out for, if
        // the DrawerGrid is unmounted and remounted (this happened when I was using routes to switch between the
        // different view modes by splitting this page into several pages) then the transitions do not work.
        // I guess we could have one view mode as a separate file and just do the switch here or actually leave it all in here but add properly typed accessors for elements[DrawerGrid.primaryPanel] (can we do some typescript typeof magic to get the fields of the drawerGridProps?)

        // ------------------------------------------------------------------------- page tree
        if (currentlySelectedBlock) {
            elements['secondary'] = (
                <BlockDetailsPanel
                    block={currentlySelectedBlock}
                    onEditBlock={onEditBlock}
                    onEditSettings={() => setViewMode(BlockListViewMode.EDIT)}
                    onDuplicate={onDuplicateBlock}
                    onDelete={onDeleteBlock}
                    openExportDialog={() => changeDialogState({ openDialog: true, type: ImportType.BLOCK })}
                    showBlockVersion={() => console.log('showPageVersion')}
                    reactivateBlockVersion={() => console.log('reactivatePageVersion')}
                />
            )
        }
        // primaryDrawerWidth={DrawerWidth.SMALL}
        // secondaryDrawerWidth={DrawerWidth.MEDIUM}
        elements['collapsible'] = true
    }

    // ------------------------------------------------------------------------- edit view
    else if (viewMode === BlockListViewMode.EDIT) {
        elements['secondary'] = (
            <BlockSettingsEditPanel
                saveIsDisabled={savingCurrentVersionDisabled}
                name={currentlySelectedBlock?.currentVersion.name ?? ''}
                onCancel={() => setViewMode(BlockListViewMode.BLOCKLIST)}
                identifier={currentlySelectedBlock?.identifier ?? ''}
                onSubmit={onSaveBlocK}
            />
        )

        elements['secondaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
        elements['primaryDisabled'] = true
    }

    // ------------------------------------------------------------------------- add view
    else if (viewMode === BlockListViewMode.ADD) {
        elements['secondary'] = (
            <BlockCreatePanel
                onCancel={() => setViewMode(BlockListViewMode.BLOCKLIST)}
                onSubmit={(label: string) => {
                    createBlock({
                        name: label,
                        websiteID: selectedWebsite.id,
                    })
                    setViewMode(BlockListViewMode.BLOCKLIST) //TODO find a better way of closing the panel after creating
                }}
            />
        )

        elements['secondaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
        elements['primaryDisabled'] = true
    } // ------------------------------------------------------------------------- publish view
    else if (viewMode === BlockListViewMode.PUBLISH) {
        elements['primary'] = (
            <PublishPanel
                unpublishedItems={unpublishedItems}
                onCancel={() => setViewMode(BlockListViewMode.BLOCKLIST)}
                onSubmit={(publishItemList: PublishItem[]) => {
                    publishItems(publishItemList)
                    setViewMode(BlockListViewMode.BLOCKLIST)
                }}
            />
        )

        elements['primaryWidth'] = DrawerWidth.LARGE
        elements['collapsible'] = false
    } // ------------------------------------------------------------------------- settings view
    else if (viewMode === BlockListViewMode.SETTINGS) {
        elements['primary'] = (
            <Box display={'flex'} flex={1} height={'100%'} flexDirection={'column'}>
                <SettingsSelectionPanel navigation={navigation} setNavigation={setNavigation} />
            </Box>
        )
        elements['primaryWidth'] = DrawerWidth.SMALL
        elements['secondaryWidth'] = DrawerWidth.SMALL
        elements['collapsible'] = false
    }

    return (
        <>
            <CMSHeader
                onClickPublish={() =>
                    viewMode === BlockListViewMode.PUBLISH
                        ? setViewMode(BlockListViewMode.BLOCKLIST)
                        : setViewMode(BlockListViewMode.PUBLISH)
                }
                onClickSettings={() =>
                    viewMode === BlockListViewMode.SETTINGS
                        ? setViewMode(BlockListViewMode.BLOCKLIST)
                        : setViewMode(BlockListViewMode.SETTINGS)
                }
                selectedTab={CMSTab.TEMPLATE}
                unpublishedItems={unpublishedItems}
                viewMode={viewMode}
            />
            <DrawerGrid
                {...elements}
                content={
                    <PagePreview
                        assets={assetsSimplified}
                        disabled={true}
                        blocks={blockData}
                        pageElements={getParsedPageElements(currentlySelectedBlock)}
                        activeBreakpoint={{
                            identifier: 'xl',
                            name: 'xl',
                            editorCssWidth: '100%',
                            fromWidthPixels: 1536,
                        }}
                    />
                }
            />
            <ExportImportDialog />
        </>
    )
}

export default BlockListPage
