import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import { WebsiteInput } from 'graphql/types'
import { Controller, useForm } from 'react-hook-form'
import { useStoreActions, useStoreState } from 'store/hooks'

export interface SettingsPanelProps {
    goBack: () => void
}

interface WebsiteSettingsInput {
    Name: string
    PreviewURL: string
}

const SettingsPanel = (props: SettingsPanelProps): JSX.Element => {
    const { goBack } = props
    const website = useStoreState((state) => state.model.selectedWebsite)
    const websiteSave = useStoreActions((actions) => actions.model.websiteSave)

    const { handleSubmit, control } = useForm<WebsiteSettingsInput>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            Name: website?.name,
            PreviewURL: website?.previewURL,
        },
    })

    const saveWebsiteData = () => {
        if (website) {
            handleSubmit((data: WebsiteSettingsInput) => {
                const ws: WebsiteInput = {
                    id: website.id,
                    name: data.Name,
                    previewURL: data.PreviewURL,
                    createdAt: website.createdAt,
                }
                websiteSave(ws)
                goBack()
            })()
        }
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">Website Settings</Typography>
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="Name"
                    control={control}
                    rules={{
                        required: 'Please enter a name',
                        maxLength: { value: 150, message: 'The name may only be 150 characters long' },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Name"
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="PreviewURL"
                    control={control}
                    rules={{
                        required: 'Please enter a PreviewURL',
                        maxLength: { value: 150, message: 'The PreviewURL may only be 150 characters long' },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="PreviewURL"
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={goBack}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={saveWebsiteData}>
                    Save
                </Button>
            </Stack>
        </Box>
    )
}

export default SettingsPanel
