import { PageElement } from 'utils/types'
import { makeRandomID } from './makeRandomID'

const treeToMap = (pageElements: PageElement[], targetMap: Record<string, PageElement>) => {
    pageElements.forEach((pe) => {
        targetMap[pe.id] = pe
        if (pe.data.regions) {
            pe.data.regions.forEach((region) => {
                treeToMap(region, targetMap)
            })
        }
    })
}

// go through the tree and find the element with the given ID in the root list or any of the nested regions and return it
export const findElement = (pageElements: PageElement[], elementId: string) => {
    const targetMap: Record<string, PageElement> = {}
    treeToMap(pageElements, targetMap)
    return targetMap[elementId]
}

// go through the tree and find the element with the given ID in the root list or any of the nested regions and call the passed function with the containing list and the element itself
export const executeOnItem = (
    pageElements: PageElement[],
    elementId: string,
    func: (pe: PageElement, list: PageElement[]) => void,
) => {
    const element = pageElements.find((pe) => pe.id === elementId)
    // if the element is in this list, execute the function
    if (element) {
        func(element, pageElements)
    } else {
        // else check if any element has regions, call this function recursively
        pageElements.forEach((pe) => {
            if (pe.data.regions) {
                pe.data.regions.forEach((region) => {
                    executeOnItem(region, elementId, func)
                })
            }
        })
    }
}

// the pagelement gets a new id and if it has regions all page elements in those also get new ids recursively
export const updateIDs = (pageElement: PageElement) => {
    pageElement.id = makeRandomID(12)
    if (pageElement.data.regions) {
        pageElement.data.regions.forEach((region) => {
            region.forEach((pe) => {
                updateIDs(pe)
            })
        })
    }
}
