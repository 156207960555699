import { Box } from '@mui/material'
import AssetGrid from 'components/AssetGrid/AssetGrid'
import CMSHeader, { CMSTab } from 'components/CMSHeader/CMSHeader'
import DrawerGrid from 'components/DrawerGrid'
import { DrawerWidth } from 'components/DrawerGrid/DrawerGrid'
import AssetDetailsPanel from 'drawerPanels/AssetDetailsPanel/AssetDetailsPanel'
import AssetFolderPanel from 'drawerPanels/AssetFolderPanel/AssetFolderPanel'
import AssetsFolderEditPanel from 'drawerPanels/AssetsFolderCreatePanel/AssetsFolderCreatePanel'
import AssetsUploadPanel from 'drawerPanels/AssetsUploadPanel/AssetsUploadPanel'
import PublishPanel from 'drawerPanels/PublishPanel/PublishPanel'
import SettingsSelectionPanel, {
    SettingsSelectionPanelNavigation,
} from 'drawerPanels/SettingsSelectionPanel/SettingsSelectionPanel'
import { Asset, AssetFolder, AssetType, PublishItem } from 'graphql/types'
import { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'store/hooks'
import { FileUpload } from 'utils/types'

export enum AssetsViewMode {
    ADD = 'ADD',
    UPLOAD = 'UPLOAD',
    PUBLISH = 'PUBLISH',
    SETTINGS = 'SETTINGS',
}

export default function AssetManagerPage() {
    const loggedInUser = useStoreState((state) => state.model.loggedInUser)
    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)

    const assets = useStoreState((state) => state.model.assets)
    const folders = useStoreState((state) => state.model.folders)
    const tagList = useStoreState((state) => state.model.tagList)
    const loadCMSData = useStoreActions((actions) => actions.model.loadCMSData)
    const createAssetFolder = useStoreActions((actions) => actions.model.createAssetFolder)
    const deleteAssetFolder = useStoreActions((actions) => actions.model.deleteAssetFolder)
    const updateAssetFolder = useStoreActions((actions) => actions.model.updateAssetFolder)
    const uploadAsset = useStoreActions((actions) => actions.model.uploadAsset)
    const deleteAsset = useStoreActions((actions) => actions.model.deleteAsset)
    const updateAsset = useStoreActions((actions) => actions.model.updateAsset)

    const [activeFolderID, setActiveFolderID] = useState<string | undefined | null>(undefined)
    const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>()
    const [selectedFolder, setSelectedFolder] = useState<AssetFolder | undefined>()
    const publishItems = useStoreActions((actions) => actions.model.publishItems)
    const [navigation, setNavigation] = useState<SettingsSelectionPanelNavigation>()

    //internal state
    const [viewMode, setViewMode] = useState<AssetsViewMode | undefined>()

    //open the create folder panel
    const openCreateFolderPanel = () => {
        setViewMode(AssetsViewMode.ADD)
        setSelectedAsset(undefined)
        setSelectedFolder(undefined)
    }

    //open the create folder panel
    const openFileUploadPanel = () => {
        setViewMode(AssetsViewMode.UPLOAD)
        setSelectedAsset(undefined)
        setSelectedFolder(undefined)
    }

    // creates a new folder
    const onCreateFolder = (name: string) => {
        if (selectedWebsite) {
            createAssetFolder({
                name,
                websiteID: selectedWebsite.id,
                parentFolderID: activeFolderID,
            })
        }
        setViewMode(undefined)
    }

    // updates the meta data of a folder
    const onFolderUpdate = (name: string, tags: string[]) => {
        if (selectedFolder) {
            updateAssetFolder({
                id: selectedFolder.id,
                parentFolderID: selectedFolder.parentFolder,
                name,
                tags,
            })
        }
        setSelectedFolder(undefined)
    }

    // updates the meta data of a file
    const onFileUpdate = (name: string, tags: string[]) => {
        if (selectedAsset) {
            updateAsset({
                AssetID: selectedAsset.id,
                folderID: selectedAsset.folder,
                name,
                tags,
            })
        }
        setSelectedAsset(undefined)
    }

    // delete a folder by the given id
    const onFolderDelete = (folderID: string) => {
        if (confirm('Are you sure you want to delete this folder?')) {
            deleteAssetFolder(folderID)
        }
    }

    // delete a file by the given id
    const onFileDelete = (fileID: string) => {
        if (confirm('Are you sure you want to delete this file?')) {
            deleteAsset(fileID)
        }
    }

    // upload files
    const uploadFiles = (files: FileUpload[], shouldImagesResized: boolean) => {
        for (const file of files) {
            let assetType = AssetType.OTHER
            if (file.type === 'application/pdf') {
                assetType = AssetType.PDF
            } else if (file.type.startsWith('image')) {
                assetType = AssetType.IMAGE
            }

            uploadAsset({
                file: file.base64Data.replaceAll('data:' + file.type + ';base64,', ''),
                folderID: activeFolderID ?? '',
                name: file.name,
                type: assetType,
                websiteID: selectedWebsite?.id ?? '',
                resizeImages: shouldImagesResized,
            })
        }
        setViewMode(undefined)
    }

    // set the current open folder
    const onSetActiveFolderID = (folderID: string | undefined | null) => {
        setActiveFolderID(folderID)
        setViewMode(undefined)
        setSelectedAsset(undefined)
        setSelectedFolder(undefined)
    }

    // close the drawer
    const onCancelDrawer = () => {
        setViewMode(undefined)
    }

    const elements: any = {}

    // ------------------------------------------------------------------------- Load CMS Data
    useEffect(() => {
        if (!loggedInUser) {
            loadCMSData()
        }
    }, [])

    // ------------------------------------------------------------------------- asset details panel
    if (selectedAsset) {
        elements['primary'] = (
            <AssetDetailsPanel
                tagList={tagList}
                asset={selectedAsset}
                onCancel={() => setSelectedAsset(undefined)}
                onSubmit={onFileUpdate}
            />
        )
        elements['primaryWidth'] = DrawerWidth.LARGE
        elements['collapsible'] = false
    }
    // ------------------------------------------------------------------------- folder panel
    else if (selectedFolder) {
        elements['primary'] = (
            <AssetFolderPanel
                folder={selectedFolder}
                tagList={tagList}
                onCancel={() => setSelectedFolder(undefined)}
                onSubmit={onFolderUpdate}
            />
        )
        elements['primaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
    }
    // ------------------------------------------------------------------------- create folder panel
    else if (viewMode === AssetsViewMode.ADD) {
        elements['primary'] = (
            <AssetsFolderEditPanel tagList={tagList} onCancel={onCancelDrawer} onSubmit={onCreateFolder} />
        )
        elements['primaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
    }
    // ------------------------------------------------------------------------- uplaod file panel
    else if (viewMode === AssetsViewMode.UPLOAD) {
        elements['primary'] = <AssetsUploadPanel onCancel={onCancelDrawer} onSubmit={uploadFiles} />
        elements['primaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
    } // ------------------------------------------------------------------------- publish view
    else if (viewMode === AssetsViewMode.PUBLISH) {
        elements['primary'] = (
            <PublishPanel
                unpublishedItems={unpublishedItems}
                onCancel={() => setViewMode(undefined)}
                onSubmit={(publishItemList: PublishItem[]) => {
                    publishItems(publishItemList)
                    setViewMode(undefined)
                }}
            />
        )

        elements['primaryWidth'] = DrawerWidth.LARGE
        elements['collapsible'] = false
    } // ------------------------------------------------------------------------- settings view
    else if (viewMode === AssetsViewMode.SETTINGS) {
        elements['primary'] = (
            <Box display={'flex'} flex={1} height={'100%'} flexDirection={'column'}>
                <SettingsSelectionPanel navigation={navigation} setNavigation={setNavigation} />
            </Box>
        )
        elements['primaryWidth'] = DrawerWidth.SMALL
        elements['secondaryWidth'] = DrawerWidth.SMALL
        elements['collapsible'] = false
    }

    return (
        <>
            <CMSHeader
                onClickPublish={() =>
                    viewMode === AssetsViewMode.PUBLISH ? setViewMode(undefined) : setViewMode(AssetsViewMode.PUBLISH)
                }
                onClickSettings={() =>
                    viewMode === AssetsViewMode.SETTINGS ? setViewMode(undefined) : setViewMode(AssetsViewMode.SETTINGS)
                }
                selectedTab={CMSTab.ASSETS}
                unpublishedItems={unpublishedItems}
                viewMode={viewMode}
            />
            <DrawerGrid
                {...elements}
                content={
                    <>
                        <AssetGrid
                            allAssets={assets}
                            allFolders={folders}
                            activeFolderID={activeFolderID}
                            setActiveFolderID={onSetActiveFolderID}
                            openCreateFolderPanel={openCreateFolderPanel}
                            openFileUploadPanel={openFileUploadPanel}
                            onAssetActivated={(a: Asset) => setSelectedAsset(a)}
                            onFolderDetails={(f: AssetFolder) => setSelectedFolder(f)}
                            onFolderDelete={onFolderDelete}
                            onFileDelete={onFileDelete}
                            onAssetSelectToggled={(id: string) => console.log('onFileSelectToggled', id)}
                        />
                    </>
                }
            />
        </>
    )
}
