import gql from 'graphql-tag'

export const VERIFY_EXCEL_TEMPLATE = gql`
    mutation verifyTemplateExcel($file: String!) {
        verifyTemplateExcel(file: $file) {
            success
            message
            templateName
            information {
                language
                urls
            }
        }
    }
`
