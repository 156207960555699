import { PageTreeEntry, Status } from 'components/PageTree/PageTree'
import { Page, State } from 'graphql/types'

export const getStatus = (page: Page): Status => {
    if (page.isTemplate) {
        return Status.IS_TEMPLATE
    } else if (page.deactivated) {
        return Status.DEACTIVATED
    } else if (page.currentVersion.state === State.UNPUBLISHED) {
        return Status.NOT_PUBLISHED
        //TODO: get version history from the page tree load. Maybe load all infos from the page? So we don't need pageLoad?
        // if(true){
        //     return Status.NOT_PUBLISHED
        // }else{
        //     return Status.PUBLISHED_WITH_CHANGES
        // }
    } else if (page.currentVersion.state === State.PUBLISHED) {
        return Status.PUBLISHED_NO_CHANGES
    }

    return Status.PUBLISHED_NO_CHANGES
}

export const convertPageListToPageTree = (pageList: Page[], currentPage: Page | null): PageTreeEntry[] => {
    const pageTree: PageTreeEntry[] = []

    pageList.forEach((page: Page) => {
        const parentID: string = currentPage ? currentPage.id : ''
        if (page.parentPageIdentifier === parentID) {
            const pageTreeEntry: PageTreeEntry = {
                id: page.id,
                name: page.currentVersion.pageSettings.htmlTitle,
                status: getStatus(page),
                subpages: convertPageListToPageTree(pageList, page),
            }
            pageTree.push(pageTreeEntry)
        }
    })
    return pageTree
}
