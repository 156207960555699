import { createStore } from 'easy-peasy' // 👈 import

import model, { Model } from './model'
import importModel, { ImportModel } from './model/importModel'
import multiPageCreateModel, { MultiPageCreateModel } from './model/multiPageCreate'
import multiPageEditModel, { MultiPageEditModel } from './model/multiPageEdit'

export interface StoreModel {
    model: Model
    importModel: ImportModel
    multiPageCreateModel: MultiPageCreateModel
    multiPageEditModel: MultiPageEditModel
}

const storeModel: StoreModel = {
    model,
    multiPageCreateModel,
    importModel,
    multiPageEditModel,
}

const store = createStore(storeModel) // 👈 create our store

export default store
