import { gql } from '@apollo/client'

export const LOAD_ASSETS = gql`
    query loadAssets($websiteID: ID!) {
        assets(websiteID: $websiteID) {
            assets {
                id
                name
                sizeBytes
                type
                url
                thumbnailUrl
                tags
                websiteID
                folder
                updatedAt
                updatedBy
                createdAt
                createdBy
            }
            folders {
                id
                websiteID
                name
                tags
                parentFolder
                createdAt
            }
        }
    }
`
