import { Grid, Typography } from '@mui/material'
import { useStoreState } from 'store/hooks'
import colors from 'utils/colors'

const MultiPageInfo = (): JSX.Element => {
    const selectedTemplate = useStoreState((state) => state.multiPageCreateModel.selectedTemplate)
    const selectedFile = useStoreState((state) => state.multiPageCreateModel.selectedFile)
    const isError = useStoreState((state) => state.multiPageCreateModel.isError)
    const messages = useStoreState((state) => state.multiPageCreateModel.messages)

    return (
        <Grid container>
            <Grid item xs={12} mt={2}>
                <Typography variant="h6">Template</Typography>
                <Typography variant="body1">{selectedTemplate}</Typography>
                <Typography variant="h6" marginTop={4}>
                    File
                </Typography>
                <Typography variant="body1">{selectedFile?.name}</Typography>
                <Typography variant="h6" marginTop={4}>
                    Status
                </Typography>
                {messages.map((message, index) => (
                    <Typography key={index} variant="body1" color={isError ? colors.warning : colors.success}>
                        {message}
                    </Typography>
                ))}
            </Grid>
            <Grid container direction={'column'} justifyContent={'space-between'} alignItems={'left'} mb={2}></Grid>
        </Grid>
    )
}

export default MultiPageInfo
