import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { useStoreActions, useStoreState } from 'store/hooks'
import { toBase64 } from 'utils/base64'
import MultiPageInfo from './MultiPageInfo'
import MultiPageSelection from './MultiPageSelection'
import MultiPageValidation from './MultiPageValidation'

export interface Language {
    title: string
    key: string
}

export enum MultiPageDrawerState {
    SELECTION = 'selection',
    VALIDATION = 'validation',
    INFO = 'info',
}

export interface MultiPageCreatePanelProps {
    onCancel: () => void
    openPageLinkSelector: (templateInfoIndex: number) => void
}

const MultiPageCreatePanel = (props: MultiPageCreatePanelProps): JSX.Element => {
    const { onCancel, openPageLinkSelector } = props

    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const drawerState = useStoreState((state) => state.multiPageCreateModel.status)
    const selectedFile = useStoreState((state) => state.multiPageCreateModel.selectedFile)
    const createPageDisabled = useStoreState((state) => state.multiPageCreateModel.createPageDisabled)
    const verifiesTemplateInfo = useStoreState((state) => state.multiPageCreateModel.verifiesTemplateInfo)

    const resetData = useStoreActions((actions) => actions.multiPageCreateModel.resetData)
    const verifyTemplateExcel = useStoreActions((actions) => actions.multiPageCreateModel.verifyTemplateExcel)
    const setStatus = useStoreActions((actions) => actions.multiPageCreateModel.setStatus)
    const setMessage = useStoreActions((actions) => actions.multiPageCreateModel.setMessage)
    const createMultiPages = useStoreActions((actions) => actions.multiPageCreateModel.createMultiPages)

    const handleCancel = () => {
        resetData()
        onCancel()
    }

    const handleVerifyExcel = () => {
        if (selectedFile == undefined) return
        toBase64(selectedFile)
            .then((data) => {
                verifyTemplateExcel(data as string)
            })
            .catch((err) => {
                console.log(err)
                setMessage({
                    isError: true,
                    messages: ["Couldn't convert file to base64"],
                })
                setStatus(MultiPageDrawerState.INFO)
            })
    }

    const handleInfoOk = () => {
        setStatus(MultiPageDrawerState.SELECTION)
        setMessage({
            isError: false,
            messages: [],
        })
    }

    const handleCreateMultiPages = () => {
        if (selectedFile == undefined) return
        toBase64(selectedFile)
            .then((data) => {
                createMultiPages({
                    fileBase64: data as string,
                    verifiesTemplateInfo: verifiesTemplateInfo,
                    websiteID: selectedWebsite?.id ?? '',
                })
            })
            .catch((err) => {
                console.log(err)
                setMessage({
                    isError: true,
                    messages: ["Couldn't convert file to base64"],
                })
                setStatus(MultiPageDrawerState.INFO)
            })
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">Create multiple pages</Typography>
            </Grid>

            {/* Render template selection */}
            {drawerState == MultiPageDrawerState.SELECTION && <MultiPageSelection />}

            {/* Render excel validation */}
            {drawerState == MultiPageDrawerState.VALIDATION && (
                <MultiPageValidation openPageLinkSelector={openPageLinkSelector} />
            )}

            {/* Render status/error */}
            {drawerState == MultiPageDrawerState.INFO && <MultiPageInfo />}

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={4}>
                {drawerState == MultiPageDrawerState.SELECTION && (
                    <>
                        <Button variant="contained" color={'secondary'} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button disabled={selectedFile == undefined} variant="contained" onClick={handleVerifyExcel}>
                            Upload & Check
                        </Button>
                    </>
                )}
                {drawerState == MultiPageDrawerState.VALIDATION && (
                    <>
                        <Button variant="contained" color={'secondary'} onClick={handleInfoOk}>
                            Cancel
                        </Button>
                        <Button variant="contained" disabled={createPageDisabled} onClick={handleCreateMultiPages}>
                            Create Pages
                        </Button>
                    </>
                )}
                {drawerState == MultiPageDrawerState.INFO && (
                    <Button variant="contained" onClick={handleInfoOk}>
                        Ok
                    </Button>
                )}
            </Stack>
        </Box>
    )
}

export default MultiPageCreatePanel
