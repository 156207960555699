import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
    TextField,
    Typography,
} from '@mui/material'
import { ChangeEvent } from 'react'
import { useStoreActions, useStoreState } from 'store/hooks'
import { getDomain } from 'utils/getEnvConfig'
import { Language } from './MultiPageCreatePanel'

const languageList: Language[] = [
    { title: 'German', key: 'DE' },
    { title: 'English', key: 'EN' },
    { title: 'Spanish', key: 'ES' },
    { title: 'French', key: 'FR' },
    { title: 'Italian', key: 'IT' },
    { title: 'Finnish', key: 'FI' },
    { title: 'Dutch', key: 'NL' },
    { title: 'Portuguese', key: 'PT' },
    { title: 'Norwegian', key: 'NO' },
    { title: 'Russian', key: 'RU' },
    { title: 'Swedish', key: 'SV' },
    { title: 'Slovenian', key: 'SL' },
    { title: 'Hungarian', key: 'HU' },
]

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})

const MultiPageSelection = (): JSX.Element => {
    const templateList = useStoreState((state) => state.model.templateList)

    const selectedTemplate = useStoreState((state) => state.multiPageCreateModel.selectedTemplate)
    const selectedFile = useStoreState((state) => state.multiPageCreateModel.selectedFile)
    const selectedLanguages = useStoreState((state) => state.multiPageCreateModel.selectedLanguages)

    const setSelectedTemplate = useStoreActions((actions) => actions.multiPageCreateModel.setSelectedTemplate)
    const setSelectedFile = useStoreActions((actions) => actions.multiPageCreateModel.setSelectedFile)
    const setSelectedLanguages = useStoreActions((actions) => actions.multiPageCreateModel.setSelectedLanguages)

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6">Instructions</Typography>
                <Typography variant="body1">
                    To bulk create a number of pages first download an excel template by choosing the template page and
                    what languages you want to create the pages in. Then fill the excel sheet with data and upload the
                    file here. The file will be checked for errors. If there are any, you will see info what to fix. If
                    everything is fine you just need to select where to put the new pages.
                </Typography>
            </Grid>
            <Grid item xs={12} mt={2}>
                <FormControl fullWidth variant={'filled'}>
                    <InputLabel id="template-select-label">Template</InputLabel>
                    <Select
                        labelId="template-select-label"
                        id="template-select"
                        label="Template"
                        value={selectedTemplate}
                        onChange={(e: SelectChangeEvent<string>) => {
                            setSelectedTemplate(e.target.value)
                        }}
                    >
                        <MenuItem disabled value={''}>
                            Please Choose
                        </MenuItem>
                        {templateList.map((template) => (
                            <MenuItem key={template.id} value={template.id}>
                                {template.currentVersion.pageSettings.htmlTitle}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} mt={2}>
                <Autocomplete
                    multiple
                    id="languages"
                    options={languageList}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    onChange={(_, value) => {
                        setSelectedLanguages(value)
                    }}
                    renderOption={(props, option, { selected }) => {
                        const { ...optionProps } = props
                        return (
                            <li {...optionProps}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )
                    }}
                    renderInput={(params) => <TextField {...params} label="Languages" placeholder="Languages" />}
                />
            </Grid>
            <Grid item xs={12} mt={2}>
                <Link
                    component="button"
                    disabled={selectedTemplate == '' || selectedLanguages.length == 0}
                    onClick={() => {
                        window.open(
                            getDomain() +
                                'template/excel?page=' +
                                selectedTemplate +
                                '&languages=' +
                                selectedLanguages.map((lang) => lang.key).join(','),
                            '_blank',
                        )
                    }}
                    variant="body2"
                >
                    Download Excel Template
                </Link>
            </Grid>
            <Grid item xs={12} mt={2}>
                {selectedFile && <p>{selectedFile.name}</p>}
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                >
                    choose file
                    <VisuallyHiddenInput
                        accept=".xls,.xlsx"
                        type="file"
                        multiple={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.files) {
                                setSelectedFile(e.target.files[0])
                            } else {
                                setSelectedFile(undefined)
                            }
                        }}
                    />
                </Button>
            </Grid>
        </Grid>
    )
}

export default MultiPageSelection
