import gql from 'graphql-tag'

export const PUBLISH_PAGES = gql`
    mutation publishPages($input: [PublishItem!]!) {
        publishPages(input: $input) {
            statusCode
            success
        }
    }
`
