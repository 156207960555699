import getEnvConfig, { logoutURI } from './getEnvConfig'

export const logout = async (token: string) => {
    let response
    const body = JSON.stringify({ token })
    try {
        // TODO: it may be neccessary to do const d = new Date() + d.getTime()
        response = await fetch(logoutURI(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: body,
        })
    } catch (e) {
        console.log(e)
    }
    if (response?.status === 200) {
        const env = getEnvConfig()
        deleteCookie('token', env.Domain)
        window.location.href = '/#/NotLoggedIn'
    }
    // only proceed once promise is resolved
    if (!response) return undefined
    const data = await response.json()
    // only proceed once second promise is resolved
    return data
}

const deleteCookie = (name: string, domain: string) => {
    if (getCookie(name)) {
        document.cookie = name + '=' + ';domain=' + domain + ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
    }
}
const getCookie = (name: string) => {
    return document.cookie.split(';').some((c) => {
        return c.trim().startsWith(name + '=')
    })
}
