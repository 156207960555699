import { useEffect } from 'react'

export default function useVersionTitle(): void {
    // const pkg = require('../../package.json')
    // --- DO NOT EDIT THIS LINE MANUALLY. UPDATE THROUGH 'yarn countCommits'
    const name = 'Qubidu CMS'
    // const version = '0.0.1'
    const appBuildNumber = 40
    // const newTitle = name + ' - ' + version + ':' + appBuildNumber
    const newTitle = name + ':' + appBuildNumber
    useEffect(
        () => {
            document.title = newTitle
        },
        // eslint-disable-next-line
        [],
    )
}
