import gql from 'graphql-tag'

export const FOLDER_DELETE = gql`
    mutation folderDelete($id: ID!) {
        folderDelete(id: $id) {
            success
            statusCode
        }
    }
`
