import { Language, MultiPageDrawerState } from 'drawerPanels/MultiPageCreatePanel/MultiPageCreatePanel'
import { Action, action, computed, Computed, thunk, Thunk } from 'easy-peasy'
import { CREATE_PAGE_FROM_TEMPLATE } from 'graphql/mutations/createPageFromTemplate'
import { VERIFY_EXCEL_TEMPLATE } from 'graphql/mutations/verifyTemplateExcel'
import { Page, TemplateExcelLngUrl, TemplateExcelUrl, VerifyTemplateExcelInformation } from 'graphql/types'
import { fetchData } from 'utils/fetchData'
import { verifyUrlIsUnique } from 'utils/linkResolver'
import store from '../index'

export type MultiPageCreateModel = {
    status: MultiPageDrawerState
    selectedTemplate: string
    selectedFile: File | undefined
    selectedLanguages: Language[]
    isLoading: boolean
    messages: string[]
    isError: boolean
    templateName: string
    verifiesTemplateInfo: TemplateExcelLngUrl[]

    // ------------------------- ACTIONS -------------------------
    setLoading: Action<MultiPageCreateModel, boolean>
    setMessage: Action<MultiPageCreateModel, { isError: boolean; messages: string[] }>
    setSelectedTemplate: Action<MultiPageCreateModel, string>
    setSelectedFile: Action<MultiPageCreateModel, File | undefined>
    setSelectedLanguages: Action<MultiPageCreateModel, Language[]>
    setStatus: Action<MultiPageCreateModel, MultiPageDrawerState>
    resetData: Action<MultiPageCreateModel>
    setVerifyTemplateInfo: Action<MultiPageCreateModel, VerifyTemplateExcelInformation[]>
    setTemplateName: Action<MultiPageCreateModel, string>
    updateMultiPageURLLink: Action<MultiPageCreateModel, { index: number; id: string; pageList: Page[] }>

    // ------------------------- COMPUTED -------------------------
    createPageDisabled: Computed<MultiPageCreateModel, boolean>

    // ------------------------- THUNK -------------------------
    verifyTemplateExcel: Thunk<MultiPageCreateModel, string>
    createMultiPages: Thunk<
        MultiPageCreateModel,
        { fileBase64: string; websiteID: string; verifiesTemplateInfo: TemplateExcelLngUrl[] }
    >
}

const multiPageCreateModel: MultiPageCreateModel = {
    selectedTemplate: '',
    templateName: '',
    selectedFile: undefined,
    selectedLanguages: [],
    status: MultiPageDrawerState.SELECTION,
    messages: [],
    verifiesTemplateInfo: [],
    isLoading: false,
    isError: false,

    // Actions
    setMessage: action((state, payload) => {
        state.isError = payload.isError
        state.messages = payload.messages
    }),

    setLoading: action((state, payload) => {
        state.isLoading = payload
    }),

    setSelectedTemplate: action((state, payload) => {
        state.selectedTemplate = payload
    }),

    setSelectedFile: action((state, payload) => {
        state.selectedFile = payload
    }),

    setSelectedLanguages: action((state, payload) => {
        state.selectedLanguages = payload
    }),

    resetData: action((state) => {
        state.selectedTemplate = ''
        state.templateName = ''
        state.selectedFile = undefined
        state.selectedLanguages = []
        state.status = MultiPageDrawerState.SELECTION
        state.messages = []
        state.isLoading = false
        state.isError = false
        state.verifiesTemplateInfo = []
    }),

    setStatus: action((state, payload) => {
        state.status = payload
    }),

    setVerifyTemplateInfo: action((state, payload) => {
        const list: TemplateExcelLngUrl[] = []

        for (const info of payload) {
            const urlList: TemplateExcelUrl[] = info.urls.map((url) => {
                return { url: url, alternativeUrl: '' }
            })

            list.push({
                language: info.language,
                urls: urlList,
                parentPageID: '',
            })
        }

        state.verifiesTemplateInfo = list
    }),

    setTemplateName: action((state, payload) => {
        state.templateName = payload
    }),

    updateMultiPageURLLink: action((state, payload) => {
        state.verifiesTemplateInfo[payload.index].parentPageID = payload.id

        //get the url data of the pages which are going to be created
        const data = state.verifiesTemplateInfo[payload.index]

        // Update all urls
        for (let index = 0; index < data.urls.length; index++) {
            const urlData = data.urls[index]
            const cleaneUpURlList = data.urls.slice(0, index)
            const alternativeUrl = verifyUrlIsUnique(urlData.url, cleaneUpURlList, payload.pageList, payload.id, 0)
            if (alternativeUrl !== urlData.url) {
                urlData.alternativeUrl = alternativeUrl
            }
        }
    }),

    // Computed
    createPageDisabled: computed((state) => {
        for (const data of state.verifiesTemplateInfo) {
            if (data.parentPageID === '') {
                return true
            }
        }
        return false
    }),

    // Thunks
    verifyTemplateExcel: thunk(async (actions, payload) => {
        actions.setLoading(true)

        //excute api call here
        const response = await fetchData(VERIFY_EXCEL_TEMPLATE, { file: payload })
        if (response.data.verifyTemplateExcel.success) {
            actions.setStatus(MultiPageDrawerState.VALIDATION)
            actions.setVerifyTemplateInfo(response.data.verifyTemplateExcel.information)
            actions.setTemplateName(response.data.verifyTemplateExcel.templateName)
        } else {
            actions.setStatus(MultiPageDrawerState.INFO)
            actions.setMessage({
                isError: true,
                messages: response.data.verifyTemplateExcel.message,
            })
        }
        actions.setLoading(false)
    }),

    createMultiPages: thunk(async (actions, payload) => {
        actions.setLoading(true)

        //excute api call here
        let isError = false
        const response = await fetchData(CREATE_PAGE_FROM_TEMPLATE, {
            file: payload.fileBase64,
            info: payload.verifiesTemplateInfo,
            websiteID: payload.websiteID,
        })
        if (response.data.createPageFromTemplate.success) {
            for (const page of response.data.createPageFromTemplate.pages) {
                store.getActions().model.addPageToPageList(page)
            }
        } else {
            isError = true
        }

        actions.setStatus(MultiPageDrawerState.INFO)
        actions.setMessage({
            isError,
            messages: [response.data.createPageFromTemplate.message],
        })

        actions.setLoading(false)
    }),
}

export default multiPageCreateModel
