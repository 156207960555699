import { Box, FormControlLabel, Switch } from '@mui/material'
import React from 'react'

export interface ToggleControlProps {
    label: string
    valueName: string
    value: string
    onValueChange: (valueName: string, newValue: string) => void
}

export const ToggleControl = (props: ToggleControlProps): JSX.Element => {
    const { valueName, value, onValueChange, label } = props

    const control = (
        <Switch
            checked={value === 'true'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onValueChange(valueName, e.target.checked ? 'true' : 'false')
            }}
        />
    )

    return (
        <Box
            sx={{
                width: '100%',
                boxSizing: 'border-box',
                mt: 1,
                mb: 2,
            }}
        >
            <FormControlLabel control={control} label={label} />
        </Box>
    )
}
