import gql from 'graphql-tag'

export const MULTI_EDIT_PAGES = gql`
    mutation multiEditPagesFromExcel($websiteID: ID!, $data: [VerifyMultiEditExcelInformationInput!]!) {
        multiEditPagesFromExcel(websiteID: $websiteID, data: $data) {
            success
            message
            pages {
                id
                parentPageIdentifier
                sortIndex
                deactivated
                isTemplate
                createdAt
                updatedAt
                currentVersion {
                    ID
                    pageID
                    state
                    contents
                    createdAt
                    updatedAt
                    updatedBy {
                        id
                        name
                    }
                    pageSettings {
                        htmlTitle
                        urlTitle
                        metaDescription
                        metaKeywords
                        robotsOption
                        includeInSitemapXML
                        pageRank
                        changeFrequency
                        language
                        translationID
                    }
                }
                historyVersions {
                    ID
                    pageID
                    state
                    contents
                    createdAt
                    updatedAt
                    updatedBy {
                        id
                        name
                    }
                    pageSettings {
                        htmlTitle
                        urlTitle
                        metaDescription
                        metaKeywords
                        robotsOption
                        includeInSitemapXML
                        pageRank
                        changeFrequency
                        language
                        translationID
                    }
                }
            }
        }
    }
`
