import gql from 'graphql-tag'

export const BLOCK_DUPLICATE = gql`
    mutation blockDuplicate($blockID: ID!) {
        blockDuplicate(blockID: $blockID) {
            id
            createdAt
            deletedAt
            identifier
            currentVersion {
                id
                name
                state
                contents
                updatedBy {
                    id
                    name
                }
                createdAt
                updatedAt
            }
            historyVersions {
                name
                id
                blockID
                state
                contents
                createdAt
                updatedAt
                updatedBy {
                    id
                    name
                }
            }
        }
    }
`
