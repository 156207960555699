import DeleteIcon from '@mui/icons-material/Delete'
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/material'
import { elementDefinitions } from 'elementDefinitions'
import cloneDeep from 'lodash/cloneDeep'
import { useState } from 'react'
import { useStoreActions, useStoreState } from 'store/hooks'
import { AddMode } from 'store/model'

export interface AddElementPanelProps {
    displayBlockOptions: boolean
    closeAddDrawer: () => void
}

enum ElementType {
    PAGE,
    BLOCK,
    CLIPBOARD,
}

const AddElementPanel = (props: AddElementPanelProps): JSX.Element => {
    const confirmAddElement = useStoreActions((actions) => actions.model.confirmAddElement)
    const removeFromClipboard = useStoreActions((actions) => actions.model.removeFromClipboard)
    const setAddMode = useStoreActions((actions) => actions.model.setAddMode)
    const pageContentClipboard = useStoreState((state) => state.model.pageContentClipboard)
    const addMode = useStoreState((state) => state.model.addMode)
    const blockList = useStoreState((state) => state.model.blockList)
    const { closeAddDrawer, displayBlockOptions } = props

    const [elementType, setElementType] = useState<ElementType>(ElementType.PAGE)

    const handleElementTypeChange = (event: React.MouseEvent<HTMLElement>, elementType: ElementType) => {
        setElementType(elementType)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stack direction={'row'} sx={{ m: 2 }} justifyContent="space-between">
                <Typography variant="h6">Add</Typography>
                <FormControl size="small">
                    <InputLabel id="add-behaviour-label">Behaviour</InputLabel>
                    <Select
                        label="Behaviour"
                        labelId="add-behaviour-label"
                        id="add-behaviour"
                        value={addMode}
                        onChange={(e) => {
                            setAddMode(e.target.value as AddMode)
                        }}
                    >
                        <MenuItem value={AddMode.ADD_SINGLE}>Add single</MenuItem>
                        <MenuItem value={AddMode.ADD_AND_EDIT}>Add + edit</MenuItem>
                        <MenuItem value={AddMode.ADD_MULTIPLE}>Add multiple</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
            <Grid sx={{ m: 2 }} justifyContent="space-between">
                <ToggleButtonGroup color="primary" value={elementType} exclusive onChange={handleElementTypeChange}>
                    <ToggleButton value={ElementType.PAGE}>Element</ToggleButton>
                    {displayBlockOptions && <ToggleButton value={ElementType.BLOCK}>Block</ToggleButton>}
                    <ToggleButton value={ElementType.CLIPBOARD}>Clipboard</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <List>
                {elementType === ElementType.PAGE &&
                    elementDefinitions.map((element) => (
                        <ListItem
                            disablePadding
                            key={element.identifier}
                            onClick={() => {
                                confirmAddElement({
                                    type: element.identifier,
                                    initialData: cloneDeep(element.initialData),
                                })
                            }}
                        >
                            <ListItemButton>
                                <ListItemText primary={element.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                {elementType === ElementType.BLOCK &&
                    displayBlockOptions &&
                    blockList.map((block) => (
                        <ListItem
                            disablePadding
                            key={block.id}
                            onClick={() => {
                                confirmAddElement({ type: '__qbd_block', initialData: { __qbd_blockId: block.id } })
                            }}
                        >
                            <ListItemButton>
                                <ListItemText primary={block.currentVersion.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                {elementType === ElementType.CLIPBOARD &&
                    pageContentClipboard.map((element, index) => (
                        <ListItem
                            disablePadding
                            key={index}
                            onClick={() => {
                                confirmAddElement({ type: element.type, initialData: element.data })
                            }}
                            secondaryAction={
                                <Tooltip title="Remove from clipboard" placement={'right'}>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            removeFromClipboard(index)
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        >
                            <ListItemButton>
                                <ListItemText primary={element.type} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                <Box sx={{ width: '100%', textAlign: 'right', padding: '1em', boxSizing: 'border-box' }}>
                    <Button variant="contained" onClick={closeAddDrawer}>
                        Close
                    </Button>
                </Box>
            </List>
        </Box>
    )
}

export default AddElementPanel
