import { useEffect } from 'react'
import { useStoreActions, useStoreState } from 'store/hooks'

export const checkVersionDaemon = () => {
    const currentlySelectedPage = useStoreState((state) => state.model.currentlySelectedPage)
    const currentlySelectedBlock = useStoreState((state) => state.model.currentlySelectedBlock)
    const setSavingCurrentVersionDisabled = useStoreActions((actions) => actions.model.setSavingCurrentVersionDisabled)
    const getLatestBlockVersion = useStoreActions((actions) => actions.model.getLatestBlockVersion)
    const getLatestPageVersion = useStoreActions((actions) => actions.model.getLatestPageVersion)

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentlySelectedPage) {
                getLatestPageVersion(currentlySelectedPage.id)
            } else if (currentlySelectedBlock) {
                getLatestBlockVersion(currentlySelectedBlock.id)
            }
        }, 1000 * 10)
        return () => {
            clearInterval(interval)
            setSavingCurrentVersionDisabled(false)
        }
    }, [])
}
