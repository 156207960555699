import DrawerGrid from 'components/DrawerGrid'
import { DrawerWidth } from 'components/DrawerGrid/DrawerGrid'
import EditorHeader from 'components/EditorHeader/EditorHeader'
import PagePreview from 'components/PagePreview/PagePreview'
import AddElementPanel from 'drawerPanels/AddElementPanel/AddElementPanel'
import AssetSelectPanel from 'drawerPanels/AssetSelectPanel/AssetSelectPanel'
import { CollectionItemData } from 'drawerPanels/EditElementPanel/DialogElements/CollectionControl'

import EditElementPanel from 'drawerPanels/EditElementPanel/EditElementPanel'
import PageTreePanel from 'drawerPanels/PageTreePanel/PageTreePanel'
import cloneDeep from 'lodash/cloneDeep'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'
import { checkVersionDaemon } from 'utils/checkVersionDaemon'
import { NavigationRoutes } from 'utils/navigationRoutes'
import {
    Breakpoint,
    EditingDataType,
    FieldLocator,
    LinkData,
    MediaData,
    PagePreviewEvent,
    PagePreviewEventType,
} from 'utils/types'

const PageEditPage = (): JSX.Element => {
    const navigate = useNavigate()

    const assets = useStoreState((state) => state.model.assets)
    const assetsSimplified = useStoreState((state) => state.model.assetsSimplified)
    const folders = useStoreState((state) => state.model.folders)
    const pageList = useStoreState((state) => state.model.pageList)
    const currentlyEditingData = useStoreState((state) => state.model.currentlyEditingData)
    const editedPageContents = useStoreState((state) => state.model.editedPageContents)
    const addElementPosition = useStoreState((state) => state.model.addElementPosition)
    const blockData = useStoreState((state) => state.model.blockData)
    const currentlyEditingElement = useStoreState((state) => state.model.currentlyEditingElement)
    const breakpoints = useStoreState((state) => state.model.breakpoints)
    const savingCurrentVersionDisabled = useStoreState((state) => state.model.savingCurrentVersionDisabled)

    const editElement = useStoreActions((actions) => actions.model.editElement)
    const changeElementValue = useStoreActions((actions) => actions.model.changeElementValue)
    const cancelEditElement = useStoreActions((actions) => actions.model.cancelEditElement)
    const copyToClipboard = useStoreActions((actions) => actions.model.copyToClipboard)
    const cutToClipboard = useStoreActions((actions) => actions.model.cutToClipboard)
    const deleteElement = useStoreActions((actions) => actions.model.deleteElement)
    const duplicateElement = useStoreActions((actions) => actions.model.duplicateElement)
    const toggleElementDeactivated = useStoreActions((actions) => actions.model.toggleElementDeactivated)
    const storeDataAndOpenElementAddDrawer = useStoreActions(
        (actions) => actions.model.storeDataAndOpenAddElementDrawer,
    )
    const cancelAddElement = useStoreActions((actions) => actions.model.cancelAddElement)
    const savePage = useStoreActions((actions) => actions.model.savePage)
    const saveBlock = useStoreActions((actions) => actions.model.saveBlock)
    const startEditingPage = useStoreActions((actions) => actions.model.startEditingPage)

    // local states for secondary drawer. If one is set, the corresponding secondary drawer will be open.
    const [pageSelectorField, setPageSelectorField] = useState<FieldLocator>()
    const [fileSelectorField, setFileSelectorField] = useState<FieldLocator>()
    const [mediaSelectorField, setMediaSelectorField] = useState<FieldLocator>()
    const [showEditorPreview, setShowEditorPreview] = useState<boolean>(false)

    const [expanded, setExpanded] = useState<string[]>([])

    const [currentBP, setCurrentBP] = useState<Breakpoint>({
        identifier: 'xl',
        name: 'xl',
        editorCssWidth: '100%',
        fromWidthPixels: 1536,
    }) // TODO pick this from available breakpoints
    const [contentHasNotChanged, setContentHasNotChanged] = useState<boolean>(false)

    let pagePreviewDisabled = false

    // const elements: Record<DrawerGridProps, any> = {}
    const elements: any = {}

    let editedItemTitle = ''
    if (currentlyEditingData) {
        editedItemTitle =
            (currentlyEditingData.type === EditingDataType.PAGE ? 'Page: ' : 'Block: ') + currentlyEditingData.name
    }

    //initalise deamon to check version
    checkVersionDaemon()

    const setPageId = (selectedPageID: string) => {
        if (pageSelectorField) {
            if (!pageSelectorField.subfield) {
                const currentLink = currentlyEditingElement?.data[pageSelectorField.fieldName] as LinkData
                currentLink.url = ''
                currentLink.__qbd_fileId = undefined
                currentLink.__qbd_pageId = selectedPageID
                changeElementValue({ valueName: pageSelectorField.fieldName, newValue: cloneDeep(currentLink) })
            } else {
                const currenltyEditingCollectionData = currentlyEditingElement?.data[
                    pageSelectorField.fieldName
                ] as CollectionItemData[]

                let currentLink = currenltyEditingCollectionData[pageSelectorField.subfield.index][
                    pageSelectorField.subfield.field
                ] as LinkData
                if (currentLink == undefined) {
                    currentLink = { url: '' }
                }
                currentLink.url = ''
                currentLink.__qbd_fileId = undefined
                currentLink.__qbd_pageId = selectedPageID

                const newCurrenltyEditingCollectionData = [...currenltyEditingCollectionData]
                newCurrenltyEditingCollectionData[pageSelectorField.subfield.index][pageSelectorField.subfield.field] =
                    currentLink
                changeElementValue({
                    valueName: pageSelectorField.fieldName,
                    newValue: newCurrenltyEditingCollectionData,
                })
            }
        }
        setPageSelectorField(undefined)
    }

    const setFileId = (selectedFileID: string) => {
        if (fileSelectorField) {
            if (!fileSelectorField.subfield) {
                const currentLink = currentlyEditingElement?.data[fileSelectorField.fieldName] as LinkData
                currentLink.url = ''
                currentLink.__qbd_pageId = undefined
                currentLink.__qbd_fileId = selectedFileID
                changeElementValue({ valueName: fileSelectorField.fieldName, newValue: cloneDeep(currentLink) })
            } else {
                const currenltyEditingCollectionData = currentlyEditingElement?.data[
                    fileSelectorField.fieldName
                ] as CollectionItemData[]

                let currentLink = currenltyEditingCollectionData[fileSelectorField.subfield.index][
                    fileSelectorField.subfield.field
                ] as LinkData
                if (currentLink == undefined) {
                    currentLink = { url: '' }
                }
                currentLink.url = ''
                currentLink.__qbd_pageId = undefined
                currentLink.__qbd_fileId = selectedFileID

                const newCurrenltyEditingCollectionData = [...currenltyEditingCollectionData]
                newCurrenltyEditingCollectionData[fileSelectorField.subfield.index][fileSelectorField.subfield.field] =
                    currentLink
                changeElementValue({
                    valueName: fileSelectorField.fieldName,
                    newValue: newCurrenltyEditingCollectionData,
                })
            }
        }

        if (mediaSelectorField) {
            if (!mediaSelectorField.subfield) {
                const currentMedia = currentlyEditingElement?.data[mediaSelectorField.fieldName] as MediaData
                currentMedia.__qbd_fileId = selectedFileID
                changeElementValue({ valueName: mediaSelectorField.fieldName, newValue: cloneDeep(currentMedia) })
            } else {
                const currenltyEditingCollectionData = currentlyEditingElement?.data[
                    mediaSelectorField.fieldName
                ] as CollectionItemData[]

                let currentMedia = currenltyEditingCollectionData[mediaSelectorField.subfield.index][
                    mediaSelectorField.subfield.field
                ] as MediaData
                if (currentMedia == undefined) {
                    currentMedia = { __qbd_fileId: '', __qbd_altText: '' }
                }
                currentMedia.__qbd_fileId = selectedFileID

                const newCurrenltyEditingCollectionData = [...currenltyEditingCollectionData]
                newCurrenltyEditingCollectionData[mediaSelectorField.subfield.index][
                    mediaSelectorField.subfield.field
                ] = currentMedia
                changeElementValue({
                    valueName: mediaSelectorField.fieldName,
                    newValue: newCurrenltyEditingCollectionData,
                })
            }
        }
        setFileSelectorField(undefined)
        setMediaSelectorField(undefined)
    }

    const removeMediaSelection = (fields: FieldLocator) => {
        if (!fields.subfield) {
            const currentMedia = currentlyEditingElement?.data[fields.fieldName] as MediaData
            currentMedia.__qbd_fileId = ''
            changeElementValue({ valueName: fields.fieldName, newValue: cloneDeep(currentMedia) })
        } else {
            const currenltyEditingCollectionData = currentlyEditingElement?.data[
                fields.fieldName
            ] as CollectionItemData[]

            const currentMedia = currenltyEditingCollectionData[fields.subfield.index][
                fields.subfield.field
            ] as MediaData
            currentMedia.__qbd_fileId = ''

            const newCurrenltyEditingCollectionData = [...currenltyEditingCollectionData]
            newCurrenltyEditingCollectionData[fields.subfield.index][fields.subfield.field] = currentMedia
            changeElementValue({
                valueName: fields.fieldName,
                newValue: newCurrenltyEditingCollectionData,
            })
        }
    }

    const savePageContent = () => {
        if (currentlyEditingData) {
            if (currentlyEditingData.type === EditingDataType.PAGE) {
                savePage({
                    pageID: currentlyEditingData.id,
                    content: JSON.stringify(editedPageContents),
                }).then(() => {
                    startEditingPage({ currentlyEditingData: undefined, pageElementContents: [] })
                    cancelEditElement()
                    navigate(NavigationRoutes.PAGE_TREE)
                })
            } else {
                saveBlock({
                    blockID: currentlyEditingData.id,
                    content: JSON.stringify(editedPageContents),
                    name: currentlyEditingData.name,
                    identifier: currentlyEditingData.identifier,
                }).then(() => {
                    startEditingPage({ currentlyEditingData: undefined, pageElementContents: [] })
                    cancelEditElement()
                    navigate(NavigationRoutes.BLOCK_LIST)
                })
            }
        }
    }

    const cancelEdit = () => {
        const targetPage =
            currentlyEditingData && currentlyEditingData.type === EditingDataType.BLOCK
                ? NavigationRoutes.BLOCK_LIST
                : NavigationRoutes.PAGE_TREE

        if (contentHasNotChanged) {
            startEditingPage({ currentlyEditingData: undefined, pageElementContents: [] })
            cancelEditElement()
            navigate(targetPage)
        } else {
            if (confirm('Do you want to discard the changes?') == true) {
                navigate(targetPage)
            }
        }
    }

    useEffect(() => {
        let contentHasChanged = false
        if (currentlyEditingData) {
            contentHasChanged = JSON.stringify(editedPageContents) === currentlyEditingData.contents
        }

        setContentHasNotChanged(contentHasChanged)
    }, [JSON.stringify(editedPageContents)])

    // ------------------------------------------------------------------------- add element panel
    if (addElementPosition) {
        elements['primary'] = (
            <AddElementPanel
                displayBlockOptions={
                    currentlyEditingData !== undefined && currentlyEditingData.type === EditingDataType.PAGE
                }
                closeAddDrawer={() => cancelAddElement()}
            />
        )
        elements['primaryWidth'] = DrawerWidth.MEDIUM
        // elements['collapsible'] = true
        pagePreviewDisabled = true
    }
    // ------------------------------------------------------------------------- edit element panel
    else if (currentlyEditingElement) {
        elements['primary'] = (
            <EditElementPanel
                openFileLinkSelector={(fields: FieldLocator) => {
                    setFileSelectorField(fields)
                }}
                openPageLinkSelector={(fields: FieldLocator) => {
                    setPageSelectorField(fields)
                }}
                openMediaSelector={(fields: FieldLocator) => {
                    setMediaSelectorField(fields)
                }}
                deleteMediaSelection={removeMediaSelection}
            />
        )
        elements['primaryWidth'] = DrawerWidth.LARGE
        elements['collapsible'] = false
        if (pageSelectorField) {
            elements['secondary'] = (
                <PageTreePanel
                    pages={pageList}
                    onSelectPage={(id) => {
                        setPageId(id)
                    }}
                    cancel={() => {
                        setPageSelectorField(undefined)
                    }}
                    selectedPage={''}
                    expanded={expanded}
                    onSetExpanded={setExpanded}
                />
            )
            elements['secondaryWidth'] = DrawerWidth.MEDIUM
            elements['primaryDisabled'] = true
        }
        if (fileSelectorField || mediaSelectorField) {
            elements['secondary'] = (
                <AssetSelectPanel
                    showOnlyImages={mediaSelectorField !== null && mediaSelectorField !== undefined} // the mediaSelector field was set, so we must show only images
                    allAssets={assets}
                    allFolders={folders}
                    onSelectAsset={(id) => {
                        setFileId(id)
                    }}
                    cancel={() => {
                        setFileSelectorField(undefined)
                        setMediaSelectorField(undefined)
                    }}
                />
            )
            elements['secondaryWidth'] = DrawerWidth.MEDIUM
            elements['primaryDisabled'] = true
        }
        pagePreviewDisabled = true
    }

    // ------------------------------------------------------------------------- edit view
    // else if (viewMode === CMSViewMode.EDIT) {
    //     elements['primary'] = (
    //         <PageTreePanel
    //             onSelectPage={(id) => {
    //                 if (id === selectedPage) setSelectedPage('')
    //                 else setSelectedPage(id)
    //             }}
    //             switchToSortMode={() => setViewMode(CMSViewMode.SORT)}
    //         />
    //     )

    //     if (selectedPage) {
    //         elements['secondary'] = <PageSettingsEditPanel cancelEdit={() => setViewMode(CMSViewMode.PAGETREE)} />
    //     }
    //     elements['secondaryWidth'] = DrawerWidth.MEDIUM
    //     elements['collapsible'] = false
    //     elements['primaryDisabled'] = true
    // }

    const previewPageEventHandler = (pe: PagePreviewEvent) => {
        if (pe.type === PagePreviewEventType.DELETE_ELEMENT) {
            deleteElement(pe.payload)
        } else if (pe.type === PagePreviewEventType.DUPLICATE_ELEMENT) {
            duplicateElement(pe.payload)
        } else if (pe.type === PagePreviewEventType.ADD_ELEMENT_BEFORE) {
            storeDataAndOpenElementAddDrawer({ elementID: pe.payload, before: true })
        } else if (pe.type === PagePreviewEventType.ADD_ELEMENT_AFTER) {
            storeDataAndOpenElementAddDrawer({ elementID: pe.payload, before: false })
        } else if (pe.type === PagePreviewEventType.COPY_ELEMENT) {
            copyToClipboard(pe.payload)
        } else if (pe.type === PagePreviewEventType.CUT_ELEMENT) {
            cutToClipboard(pe.payload)
        } else if (pe.type === PagePreviewEventType.EDIT_ELEMENT) {
            editElement(pe.payload)
        } else if (pe.type === PagePreviewEventType.TOGGLE_ELEMENT_DEACTIVATED) {
            toggleElementDeactivated(pe.payload)
        }
    }

    return (
        <>
            <EditorHeader
                editedItemTitle={editedItemTitle}
                disableSaveButton={contentHasNotChanged || savingCurrentVersionDisabled}
                breakpoints={breakpoints}
                selectedBreakpointId={currentBP.identifier}
                onCancelClick={cancelEdit}
                onSaveClick={savePageContent}
                onBreakpointSelected={(newBp) => {
                    setCurrentBP(newBp)
                }}
                onChangeEditorPreviewClick={() => {
                    setShowEditorPreview(!showEditorPreview)
                }}
                showEditorPreview={showEditorPreview}
            />
            <DrawerGrid
                {...elements}
                content={
                    <PagePreview
                        assets={assetsSimplified}
                        activeBreakpoint={currentBP}
                        pageElements={editedPageContents}
                        onPreviewPageEvent={previewPageEventHandler}
                        blocks={blockData}
                        disabled={pagePreviewDisabled}
                        editHelpersVisible={!showEditorPreview}
                    />
                }
                contentCentered={true}
            />
        </>
    )
}

export default PageEditPage
