export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
}

export type Asset = {
    __typename?: 'Asset'
    createdAt: Scalars['Int']
    createdBy: Scalars['String']
    folder?: Maybe<Scalars['String']>
    id: Scalars['ID']
    name: Scalars['String']
    sizeBytes: Scalars['Int']
    tags?: Maybe<Array<Scalars['String']>>
    thumbnailUrl?: Maybe<Scalars['String']>
    type: AssetType
    updatedAt: Scalars['Int']
    updatedBy: Scalars['String']
    url: Scalars['String']
    websiteID: Scalars['String']
}

export type AssetFolder = {
    __typename?: 'AssetFolder'
    createdAt: Scalars['Int']
    id: Scalars['ID']
    name: Scalars['String']
    parentFolder?: Maybe<Scalars['String']>
    tags?: Maybe<Array<Scalars['String']>>
    websiteID: Scalars['ID']
}

export type AssetResponse = {
    __typename?: 'AssetResponse'
    assets: Array<Asset>
    folders: Array<AssetFolder>
}

export enum AssetType {
    IMAGE = 'IMAGE',
    OTHER = 'OTHER',
    PDF = 'PDF',
}

export type AssetUpdateInput = {
    AssetID: Scalars['ID']
    folderID?: InputMaybe<Scalars['ID']>
    name: Scalars['String']
    tags?: InputMaybe<Array<Scalars['String']>>
}

export type AssetUploadInput = {
    file: Scalars['String']
    folderID: Scalars['String']
    name: Scalars['String']
    resizeImages: Scalars['Boolean']
    type: AssetType
    websiteID: Scalars['String']
}

export type Block = {
    __typename?: 'Block'
    createdAt: Scalars['Int']
    currentVersion: BlockVersion
    deletedAt?: Maybe<Scalars['Int']>
    historyVersions: Array<BlockVersion>
    id: Scalars['ID']
    identifier: Scalars['String']
}

export type BlockCreateInput = {
    name: Scalars['String']
    websiteID: Scalars['ID']
}

export type BlockSaveInput = {
    blockID: Scalars['ID']
    content: Scalars['String']
    identifier: Scalars['String']
    name: Scalars['String']
}

export type BlockVersion = {
    __typename?: 'BlockVersion'
    blockID: Scalars['ID']
    contents: Scalars['String']
    createdAt: Scalars['Int']
    id: Scalars['ID']
    name: Scalars['String']
    state: State
    updatedAt: Scalars['Int']
    updatedBy: User
}

export type CreatePageInput = {
    parentPageID?: InputMaybe<Scalars['ID']>
    title: Scalars['String']
    websiteID: Scalars['ID']
}

export type DuplicatePageInput = {
    pageID: Scalars['ID']
}

export type ElementDefinition = {
    __typename?: 'ElementDefinition'
    category: Scalars['String']
    fields: Array<Maybe<Scalars['String']>>
    help?: Maybe<Scalars['String']>
    id: Scalars['String']
    img: Scalars['String']
    template: Scalars['String']
}

export type ErrorConfig = {
    __typename?: 'ErrorConfig'
    content: Scalars['String']
    messages?: Maybe<Array<Scalars['String']>>
    title: Scalars['String']
}

export type FolderCreateInput = {
    name: Scalars['String']
    parentFolderID?: InputMaybe<Scalars['ID']>
    websiteID: Scalars['ID']
}

export type FolderUpdateInput = {
    id: Scalars['ID']
    name: Scalars['String']
    parentFolderID?: InputMaybe<Scalars['ID']>
    tags?: InputMaybe<Array<Scalars['String']>>
}

export enum Frequency {
    ALWAYS = 'ALWAYS',
    DAILY = 'DAILY',
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
    NEVER = 'NEVER',
    WEEKLY = 'WEEKLY',
    YEARLY = 'YEARLY',
}

export type ImportDataContentInput = {
    changeFrequency: Scalars['String']
    content: Scalars['String']
    identifier: Scalars['String']
    includeInSitemapXML: Scalars['Boolean']
    language: Scalars['String']
    metaDescription: Scalars['String']
    metaKeywords: Scalars['String']
    name: Scalars['String']
    pageRank: Scalars['String']
    robotsOption: Scalars['String']
    urlTitle: Scalars['String']
}

export type ImportDataInput = {
    content: ImportDataContentInput
    media: Array<ImportFileDataInput>
    type: ImportType
    websiteID: Scalars['ID']
}

export type ImportFileDataInput = {
    folderPath: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    type: Scalars['String']
    url: Scalars['String']
}

export type ImportResponse = {
    __typename?: 'ImportResponse'
    block?: Maybe<Block>
    message: Scalars['String']
    page?: Maybe<Page>
    success: Scalars['Boolean']
}

export enum ImportType {
    BLOCK = 'BLOCK',
    PAGE = 'PAGE',
}

export type LoggedInUser = {
    __typename?: 'LoggedInUser'
    defaultWebsiteID: Scalars['ID']
    id: Scalars['ID']
    name: Scalars['String']
}

export enum MultiEditAllowedTypes {
    ALL = 'ALL',
    MEDIA_LINK = 'MEDIA_LINK',
    TEXT = 'TEXT',
}

export type MultiEditExcelResponse = {
    __typename?: 'MultiEditExcelResponse'
    excel: Scalars['String']
    message: Scalars['String']
    success: Scalars['Boolean']
}

export type MultiResponse = {
    __typename?: 'MultiResponse'
    message: Scalars['String']
    pages: Array<Page>
    success: Scalars['Boolean']
}

export type Mutation = {
    __typename?: 'Mutation'
    assetDelete?: Maybe<Response>
    assetUpdate: Asset
    assetUpload: Asset
    blockCreate: Block
    blockDelete?: Maybe<Response>
    blockDuplicate: Block
    blockSave: Block
    createPage: Page
    createPageFromTemplate: MultiResponse
    deactivatePage?: Maybe<Response>
    deletePage?: Maybe<Response>
    duplicatePage: Page
    folderCreate: AssetFolder
    folderDelete?: Maybe<Response>
    folderUpdate: AssetFolder
    importPage: ImportResponse
    multiEditPagesFromExcel?: Maybe<MultiResponse>
    pageTreeReorder?: Maybe<Response>
    publishPages?: Maybe<Response>
    savePage: SavePageResponse
    verifyMultiEditExcel?: Maybe<VerifyMultiEditExcelResponse>
    verifyTemplateExcel?: Maybe<VerifyTemplateExcelResponse>
    websiteSave: Website
}

export type MutationAssetDeleteArgs = {
    id: Scalars['ID']
}

export type MutationAssetUpdateArgs = {
    input: AssetUpdateInput
}

export type MutationAssetUploadArgs = {
    input: AssetUploadInput
}

export type MutationBlockCreateArgs = {
    input: BlockCreateInput
}

export type MutationBlockDeleteArgs = {
    ids: Array<Scalars['String']>
}

export type MutationBlockDuplicateArgs = {
    blockID: Scalars['ID']
}

export type MutationBlockSaveArgs = {
    input: BlockSaveInput
}

export type MutationCreatePageArgs = {
    input: CreatePageInput
}

export type MutationCreatePageFromTemplateArgs = {
    file: Scalars['String']
    info: Array<TemplateExcelLngUrl>
    websiteID: Scalars['ID']
}

export type MutationDeactivatePageArgs = {
    pageID: Scalars['ID']
}

export type MutationDeletePageArgs = {
    pageID: Scalars['ID']
}

export type MutationDuplicatePageArgs = {
    input: DuplicatePageInput
}

export type MutationFolderCreateArgs = {
    input: FolderCreateInput
}

export type MutationFolderDeleteArgs = {
    id: Scalars['ID']
}

export type MutationFolderUpdateArgs = {
    input: FolderUpdateInput
}

export type MutationImportPageArgs = {
    input: ImportDataInput
}

export type MutationMultiEditPagesFromExcelArgs = {
    data: Array<VerifyMultiEditExcelInformationInput>
    websiteID: Scalars['ID']
}

export type MutationPageTreeReorderArgs = {
    input: Array<OrderItemInput>
}

export type MutationPublishPagesArgs = {
    input: Array<PublishItem>
}

export type MutationSavePageArgs = {
    input: SavePageInput
}

export type MutationVerifyMultiEditExcelArgs = {
    elementDefinition: Scalars['String']
    file: Scalars['String']
    websiteID: Scalars['ID']
}

export type MutationVerifyTemplateExcelArgs = {
    file: Scalars['String']
}

export type MutationWebsiteSaveArgs = {
    input: WebsiteInput
}

export type OrderItemInput = {
    id: Scalars['ID']
    parent?: InputMaybe<Scalars['ID']>
}

export type Page = {
    __typename?: 'Page'
    createdAt: Scalars['Int']
    currentVersion: PageVersion
    deactivated: Scalars['Boolean']
    deletedAt?: Maybe<Scalars['Int']>
    historyVersions: Array<PageVersion>
    id: Scalars['ID']
    isTemplate: Scalars['Boolean']
    parentPageIdentifier?: Maybe<Scalars['ID']>
    sortIndex: Scalars['Int']
    updatedAt: Scalars['Int']
}

export enum PageRank {
    EIGHT = 'EIGHT',
    FIVE = 'FIVE',
    FOUR = 'FOUR',
    NINE = 'NINE',
    ONE = 'ONE',
    SEVEN = 'SEVEN',
    SIX = 'SIX',
    TEN = 'TEN',
    THREE = 'THREE',
    TWO = 'TWO',
}

export type PageSettingInput = {
    changeFrequency: Frequency
    htmlTitle: Scalars['String']
    includeInSitemapXML: Scalars['Boolean']
    isTemplate: Scalars['Boolean']
    language: Scalars['String']
    linkedLanguagePagesIds?: InputMaybe<Array<Scalars['String']>>
    metaDescription: Scalars['String']
    metaKeywords: Scalars['String']
    pageRank: PageRank
    robotsOption: RobotsOption
    translationID?: InputMaybe<Scalars['String']>
    urlTitle: Scalars['String']
}

export type PageSettings = {
    __typename?: 'PageSettings'
    changeFrequency: Frequency
    htmlTitle: Scalars['String']
    includeInSitemapXML: Scalars['Boolean']
    language: Scalars['String']
    metaDescription: Scalars['String']
    metaKeywords: Scalars['String']
    pageRank: PageRank
    robotsOption: RobotsOption
    translationID?: Maybe<Scalars['String']>
    urlTitle: Scalars['String']
}

export type PageVersion = {
    __typename?: 'PageVersion'
    ID: Scalars['String']
    contents: Scalars['String']
    createdAt: Scalars['Int']
    pageID: Scalars['String']
    pageSettings: PageSettings
    state: State
    updatedAt: Scalars['Int']
    updatedBy: User
}

export type PublishItem = {
    itemID: Scalars['ID']
    type: Scalars['String']
}

export type Query = {
    __typename?: 'Query'
    assets: AssetResponse
    loadBlock: Block
    loadBlocks: Array<Block>
    loadMe?: Maybe<LoggedInUser>
    loadPage: Page
    loadPages: Array<Page>
    loadWebsite: Website
    multiEditDownloadExcel?: Maybe<MultiEditExcelResponse>
}

export type QueryAssetsArgs = {
    websiteID: Scalars['ID']
}

export type QueryLoadBlockArgs = {
    blockID: Scalars['ID']
}

export type QueryLoadBlocksArgs = {
    websiteID: Scalars['ID']
}

export type QueryLoadPageArgs = {
    pageID: Scalars['ID']
}

export type QueryLoadPagesArgs = {
    websiteID: Scalars['ID']
}

export type QueryLoadWebsiteArgs = {
    websiteID: Scalars['ID']
}

export type QueryMultiEditDownloadExcelArgs = {
    allowedTypes: MultiEditAllowedTypes
    elementDefinition: Scalars['String']
    pages: Array<Scalars['ID']>
    websiteID: Scalars['ID']
}

export type Response = {
    __typename?: 'Response'
    statusCode: Scalars['Int']
    success: Scalars['Boolean']
}

export enum RobotsOption {
    BOTH = 'BOTH',
    FOLLOW = 'FOLLOW',
    INDEX = 'INDEX',
    NONE = 'NONE',
}

export type SavePageInput = {
    content?: InputMaybe<Scalars['String']>
    pageID: Scalars['ID']
    pageSettings?: InputMaybe<PageSettingInput>
}

export type SavePageResponse = {
    __typename?: 'SavePageResponse'
    linkedPages: Array<Page>
    page: Page
}

export enum State {
    DELETED = 'DELETED',
    PUBLISHED = 'PUBLISHED',
    UNPUBLISHED = 'UNPUBLISHED',
}

export type TemplateExcelLngUrl = {
    language: Scalars['String']
    parentPageID: Scalars['ID']
    urls: Array<TemplateExcelUrl>
}

export type TemplateExcelUrl = {
    alternativeUrl: Scalars['String']
    url: Scalars['String']
}

export type User = {
    __typename?: 'User'
    id: Scalars['ID']
    name: Scalars['String']
}

export type VerifyMultiEditExcelInformation = {
    __typename?: 'VerifyMultiEditExcelInformation'
    content: Scalars['String']
    hasChanges: Scalars['Boolean']
    id: Scalars['ID']
    metaDescription: Scalars['String']
    metaKeywords: Scalars['String']
    pageTitle: Scalars['String']
    pageUrl: Scalars['String']
}

export type VerifyMultiEditExcelInformationInput = {
    content: Scalars['String']
    hasChanges: Scalars['Boolean']
    id: Scalars['ID']
    metaDescription: Scalars['String']
    metaKeywords: Scalars['String']
    pageTitle: Scalars['String']
    pageUrl: Scalars['String']
}

export type VerifyMultiEditExcelResponse = {
    __typename?: 'VerifyMultiEditExcelResponse'
    information: Array<VerifyMultiEditExcelInformation>
    message: Array<Scalars['String']>
    success: Scalars['Boolean']
}

export type VerifyTemplateExcelInformation = {
    __typename?: 'VerifyTemplateExcelInformation'
    language: Scalars['String']
    urls: Array<Scalars['String']>
}

export type VerifyTemplateExcelResponse = {
    __typename?: 'VerifyTemplateExcelResponse'
    information: Array<VerifyTemplateExcelInformation>
    message: Array<Scalars['String']>
    success: Scalars['Boolean']
    templateName: Scalars['String']
}

export type Website = {
    __typename?: 'Website'
    createdAt: Scalars['Int']
    elementDefinition: Array<ElementDefinition>
    id: Scalars['ID']
    name: Scalars['String']
    previewURL: Scalars['String']
    updatedAt: Scalars['Int']
    updatedBy: User
}

export type WebsiteInput = {
    createdAt: Scalars['Int']
    id: Scalars['ID']
    name: Scalars['String']
    previewURL: Scalars['String']
}
