import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material'
import { Page } from 'graphql/types'
import { Controller, useForm } from 'react-hook-form'

export interface PageCreatePanelProps {
    parentPage?: Page
    onCancel: () => void
    onSubmit: (title: string, parenPage: Page | null) => void
}

interface PageCreateInputs {
    Title: string
    IsSubpage: boolean
}

const PageCreatePanel = (props: PageCreatePanelProps): JSX.Element => {
    const { onCancel, parentPage, onSubmit } = props
    const { handleSubmit, control } = useForm<PageCreateInputs>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            Title: '',
            IsSubpage: true,
        },
    })

    const handleOnSave = () => {
        handleSubmit((data: PageCreateInputs) => {
            onSubmit(data.Title, data.IsSubpage && parentPage ? parentPage : null)
        })()
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">New Page</Typography>
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="Title"
                    control={control}
                    rules={{
                        required: 'Please enter a page title',
                        maxLength: { value: 150, message: 'The page title may only be 150 characters long.' },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Page Title"
                            inputProps={{ maxLength: 150 }}
                            fullWidth={true}
                            {...field}
                            helperText={fieldState.error?.message ?? ''}
                            variant={'filled'}
                        />
                    )}
                />
            </Grid>
            {parentPage && (
                <Grid container item mb={2}>
                    <Controller
                        name="IsSubpage"
                        control={control}
                        render={({ field }) => {
                            return (
                                <FormControlLabel
                                    control={<Checkbox checked={field.value} {...field} />}
                                    label={'Subpage of "' + parentPage.currentVersion.pageSettings.htmlTitle + '"'}
                                />
                            )
                        }}
                    />
                </Grid>
            )}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleOnSave}>
                    Save
                </Button>
            </Stack>
        </Box>
    )
}

export default PageCreatePanel
