import gql from 'graphql-tag'

export const CREATE_PAGE_FROM_TEMPLATE = gql`
    mutation createPageFromTemplate($file: String!, $info: [TemplateExcelLngUrl!]!, $websiteID: ID!) {
        createPageFromTemplate(file: $file, info: $info, websiteID: $websiteID) {
            success
            message
            pages {
                id
                parentPageIdentifier
                sortIndex
                deactivated
                isTemplate
                createdAt
                updatedAt
                currentVersion {
                    ID
                    pageID
                    state
                    contents
                    createdAt
                    updatedAt
                    updatedBy {
                        id
                        name
                    }
                    pageSettings {
                        htmlTitle
                        urlTitle
                        metaDescription
                        metaKeywords
                        robotsOption
                        includeInSitemapXML
                        pageRank
                        changeFrequency
                        language
                        translationID
                    }
                }
                historyVersions {
                    ID
                    pageID
                    state
                    contents
                    createdAt
                    updatedAt
                    updatedBy {
                        id
                        name
                    }
                    pageSettings {
                        htmlTitle
                        urlTitle
                        metaDescription
                        metaKeywords
                        robotsOption
                        includeInSitemapXML
                        pageRank
                        changeFrequency
                        language
                        translationID
                    }
                }
            }
        }
    }
`
