import { Grid, Typography } from '@mui/material'
import { useStoreState } from 'store/hooks'
import { resolvePagelURL } from 'utils/linkResolver'

const MultiEditPageValidation = (): JSX.Element => {
    const selectedFile = useStoreState((state) => state.multiPageEditModel.selectedFile)
    const verifyMultiEditExcelInformations = useStoreState(
        (state) => state.multiPageEditModel.verifyMultiEditExcelInformations,
    )
    const pageList = useStoreState((state) => state.model.pageList)

    const noChanges = verifyMultiEditExcelInformations.filter((v) => v.hasChanges == false)
    const hasChanges = verifyMultiEditExcelInformations.filter((v) => v.hasChanges == true)

    return (
        <Grid container>
            <Grid item xs={12} mt={2}>
                <Typography variant="h6">File</Typography>
                <Typography variant="body1">{selectedFile?.name}</Typography>
                <Typography variant="h6" marginTop={4}>
                    Status
                </Typography>
                <Typography variant="body1">File ok</Typography>
                <Typography variant="h6" marginTop={4}>
                    Following pages will be edited
                </Typography>
                {hasChanges.map((page, index) => (
                    <Typography key={index} variant="body1">
                        {resolvePagelURL('Page/' + page.id, pageList, false)}
                    </Typography>
                ))}
                <Typography variant="h6" marginTop={4}>
                    Following pages will not be edited
                </Typography>
                {noChanges.map((page, index) => (
                    <Typography key={index} variant="body1">
                        {resolvePagelURL('Page/' + page.id, pageList, false)}
                    </Typography>
                ))}
            </Grid>
        </Grid>
    )
}

export default MultiEditPageValidation
