import React from 'react'
import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview'
import { CustomData } from './types'
import styles from './CustomDragPreview.module.css'
import { Typography } from '@mui/material'

type Props = {
    monitorProps: DragLayerMonitorProps<CustomData>
}

export const CustomDragPreview: React.FC<Props> = (props) => {
    const item = props.monitorProps.item

    return (
        <div className={styles.root}>
            <Typography variant="body1">{item.text}</Typography>
        </div>
    )
}
