import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
// import useI18n from 'utils/functions/useI18n'
// import TestIDs from 'utils/TestIDs'
import { Tooltip } from '@mui/material'
import './DrawerGrid.css'

export enum DrawerWidth {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

type Props = {
    primary?: JSX.Element
    primaryWidth?: DrawerWidth
    primaryDisabled?: boolean
    secondary?: JSX.Element
    secondaryWidth?: DrawerWidth
    ternary?: JSX.Element
    content?: JSX.Element
    contentCentered?: boolean
    collapsible?: boolean
}

export type DrawerGridProps = keyof Props

const DrawerGrid = (props: Props): JSX.Element => {
    // const { t } = useI18n()
    const [collapsed, setCollapsed] = useState(false)

    const {
        content,
        contentCentered,
        primary,
        primaryWidth,
        primaryDisabled,
        secondary,
        secondaryWidth,
        collapsible,
        ternary,
    } = props

    const primaryExists = primary !== undefined
    const secondaryExists = secondary !== undefined
    const contentExists = content !== undefined

    const primaryClasses: string[] = [],
        secondaryClasses: string[] = [],
        previewClasses: string[] = ['previewContainer']

    // set the widths of the drawers or default to small
    primaryClasses.push(primaryWidth ? primaryWidth : DrawerWidth.SMALL)
    secondaryClasses.push(secondaryWidth ? secondaryWidth : DrawerWidth.SMALL)

    // tell the secondary how wide the primary is through a class
    // IMPORTANT! this must come before "open" class, see css definition
    secondaryClasses.push(primaryWidth ? 'p_' + primaryWidth : 'p_' + DrawerWidth.SMALL)

    // check which ones are open and in combination which one is active
    if (primaryExists && !collapsed) {
        primaryClasses.push('open')
        previewClasses.push(`primary_${primaryWidth ? primaryWidth : DrawerWidth.SMALL}`)
    }
    if (secondaryExists && !collapsed) {
        secondaryClasses.push('open', 'active')
        previewClasses.push(`secondary_${secondaryWidth ? secondaryWidth : DrawerWidth.SMALL}`)
    }
    if (primaryExists && !secondaryExists) primaryClasses.push('active')

    return (
        <div className={previewClasses.join(' ')}>
            <div id="primaryDrawer" className={primaryClasses.join(' ')}>
                {primaryExists ? props.primary : null}
                {primaryDisabled && <div className="disabledOverlay"></div>}
            </div>
            <div id="secondaryDrawer" className={secondaryClasses.join(' ')}>
                {secondaryExists ? props.secondary : null}
            </div>
            <div
                id="contentArea"
                className={contentExists && !primaryExists && !secondaryExists ? 'active' : ''}
                style={contentCentered ? { display: 'flex', justifyContent: 'center' } : {}}
            >
                {ternary ? ternary : contentExists ? props.content : null}
            </div>
            {collapsible && (
                <Tooltip title={collapsed ? 'Open drawer' : 'Close drawer'}>
                    <IconButton
                        id={'collapse'}
                        onClick={() => {
                            setCollapsed(!collapsed)
                        }}
                    >
                        {collapsed ? <ArrowCircleRight /> : <ArrowCircleLeft />}
                    </IconButton>
                </Tooltip>
            )}
        </div>
    )
}

export default DrawerGrid
