import gql from 'graphql-tag'

export const VERIFY_MULTI_EDIT_EXCEL = gql`
    mutation verifyMultiEditExcel($websiteID: ID!, $file: String!, $elementDefinition: String!) {
        verifyMultiEditExcel(websiteID: $websiteID, file: $file, elementDefinition: $elementDefinition) {
            success
            message
            information {
                id
                pageTitle
                pageUrl
                metaDescription
                metaKeywords
                content
                hasChanges
            }
        }
    }
`
