import {
    Delete,
    Edit,
    FileCopy,
    History,
    Search,
    Settings,
    SettingsBackupRestore,
    Visibility,
    VisibilityOff,
} from '@mui/icons-material'
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { format } from 'date-fns'
import { Page, PageVersion } from 'graphql/types'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'

export interface PageDetailsPanelrops {
    onEditPage: (page: Page) => void
    onDeactivate: (pageID: string) => void
    onEditPageSettings: (pageID: string) => void
    onDuplicate: (pageID: string) => void
    onDelete: (pageID: string) => void
    showPageVersion: (pageVersionID: string) => void
    reactivatePageVersion: (pageVersionID: string) => void
    openExportDialiog: () => void
    page: Page
}

const timeFormat = 'dd.MM.yyyy HH:mm'

const getLastPublished = (page: Page): string => {
    const pageHistoryVersions = cloneDeep(page.historyVersions)
    for (const pageVersion of pageHistoryVersions.sort((a, b) => b.updatedAt - a.updatedAt)) {
        //TODO: move state in pageversion
        if (pageVersion.state === 'PUBLISHED') {
            return format(new Date(pageVersion.updatedAt), timeFormat)
        }
    }

    return '-'
}

const renderVersionEntry = (
    pv: PageVersion,
    isCurrentVersion: boolean,
    showPageVersion: (pageVersionID: string) => void,
    reactivatePageVersion: (pageVersionID: string) => void,
) => {
    return (
        <>
            <Box display="flex" flexDirection="row" flex={1}>
                <Grid container flexDirection={'row'} alignItems={'center'}>
                    <Grid xs={10} item>
                        <Typography>{format(new Date(pv.updatedAt), timeFormat)}</Typography>
                        <Typography>{pv.updatedBy.name}</Typography>
                    </Grid>
                    <Grid xs={2} item flexDirection="column">
                        <Tooltip title={'Show'} placement="right">
                            <Button
                                variant="text"
                                onClick={() => {
                                    showPageVersion(pv.ID)
                                }}
                            >
                                <Search />
                            </Button>
                        </Tooltip>
                        {!isCurrentVersion ? (
                            <Tooltip title={'Reactivate'} placement="right">
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        reactivatePageVersion(pv.ID)
                                    }}
                                >
                                    <SettingsBackupRestore />
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button style={{ opacity: 0 }} disabled>
                                <SettingsBackupRestore />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Divider />
        </>
    )
}

const PageDetailsPanel = (props: PageDetailsPanelrops): JSX.Element => {
    const { onEditPage, onDeactivate, onEditPageSettings, onDuplicate, onDelete, openExportDialiog, page } = props

    const lastPublished = getLastPublished(page)

    const [displayPageVersion, setDisplayPageVersion] = useState<boolean>(false)

    const showPageVersion = (v: string) => {
        console.log('showPageVersion', v)
    }
    const reactivatePageVersion = (v: string) => {
        console.log('reactivatePageVersion', v)
    }

    useEffect(() => {
        setDisplayPageVersion(false)
    }, [page])

    return (
        <Grid container direction="column" p={2} spacing={1}>
            <Grid container item direction="row" alignItems={'center'}>
                <Grid item xs={10}>
                    <Typography variant="h6">{page.currentVersion.pageSettings.htmlTitle}</Typography>
                </Grid>
                <Grid item xs={2} justifyItems={'flex-end'}>
                    <Tooltip title={'Edit page'} placement={'right'}>
                        <IconButton
                            onClick={() => {
                                onEditPage(page)
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item direction="row">
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row">ID</TableCell>
                                <TableCell align="right">{page.id.replace('Page/', '')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">Last change</TableCell>
                                <TableCell align="right">
                                    {format(new Date(page.currentVersion.updatedAt), timeFormat)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">Changed by</TableCell>
                                <TableCell align="right">
                                    <Tooltip title={page.currentVersion.updatedBy.name} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '148px',
                                            }}
                                        >
                                            {page.currentVersion.updatedBy.name}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">Last publish</TableCell>
                                <TableCell align="right">{lastPublished}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    St<a onClick={openExportDialiog}>a</a>tus
                                </TableCell>
                                <TableCell align="right">{page.currentVersion.state}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid container item direction="row">
                <Grid item xs={2}>
                    <Tooltip title={page.deactivated ? 'Activate' : 'Deactivate'}>
                        <IconButton
                            // disabled={page.deactivated}
                            onClick={() => {
                                onDeactivate(page.id)
                            }}
                        >
                            {!page.deactivated && <Visibility />}
                            {page.deactivated && <VisibilityOff />}
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={10} display="flex" alignContent="end">
                    <Tooltip title={'Page settings'}>
                        <IconButton
                            onClick={() => {
                                onEditPageSettings(page.id)
                            }}
                        >
                            <Settings />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Duplicate page'}>
                        <IconButton
                            onClick={() => {
                                onDuplicate(page.id)
                            }}
                        >
                            <FileCopy />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Delete page'}>
                        <IconButton
                            onClick={() => {
                                onDelete(page.id)
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Version History'}>
                        <IconButton
                            onClick={() => {
                                setDisplayPageVersion(!displayPageVersion)
                            }}
                        >
                            <History />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {displayPageVersion && (
                <Grid container item direction="column">
                    <Grid item xs={12}>
                        <Typography variant="h5">Versions</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {renderVersionEntry(
                            page.currentVersion,
                            true,
                            (v) => showPageVersion(v),
                            (v) => reactivatePageVersion(v),
                        )}
                    </Grid>
                    {page.historyVersions.slice(1).map((pageVersion: PageVersion, index: number) => {
                        return (
                            <React.Fragment key={pageVersion.ID}>
                                {index > 0 && <Divider />}
                                {renderVersionEntry(pageVersion, false, showPageVersion, reactivatePageVersion)}
                            </React.Fragment>
                        )
                    })}
                </Grid>
            )}
        </Grid>
    )
}

export default PageDetailsPanel
