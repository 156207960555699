import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { ErrorConfig } from 'graphql/types'

export interface ErrorDialogProps {
    errorConfig?: ErrorConfig
    onClick: () => void
}

const ErrorDialog = (props: ErrorDialogProps): JSX.Element => {
    const ec = props.errorConfig

    return (
        <>
            {ec && (
                <Dialog disableEscapeKeyDown={false} aria-labelledby="customized-dialog-title" open={true}>
                    <DialogTitle id="customized-dialog-title">{ec.title}</DialogTitle>
                    <DialogContent style={{ padding: '24px' }} dividers>
                        <Typography>{ec.content}</Typography>
                        {ec.messages && <Typography>{ec.messages}</Typography>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClick}>Ok</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export default ErrorDialog
