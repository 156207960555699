import { gql } from '@apollo/client'

export const MULTI_EDIT_DOWNLOAD_EXCEL = gql`
    query multiEditDownloadExcel(
        $websiteID: ID!
        $pages: [ID!]!
        $elementDefinition: String!
        $allowedTypes: MultiEditAllowedTypes!
    ) {
        multiEditDownloadExcel(
            websiteID: $websiteID
            pages: $pages
            elementDefinition: $elementDefinition
            allowedTypes: $allowedTypes
        ) {
            success
            message
            excel
        }
    }
`
