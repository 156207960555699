import {
    Button,
    FormControlLabel,
    Grid,
    Stack,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { Breakpoint } from 'utils/types'

export interface EditorHeaderProps {
    editedItemTitle: string
    selectedBreakpointId: string
    breakpoints: Breakpoint[]
    showEditorPreview: boolean
    disableSaveButton: boolean
    onBreakpointSelected: (breakpoint: Breakpoint) => void
    onSaveClick: () => void
    onCancelClick: () => void
    onChangeEditorPreviewClick: () => void
}

const EditorHeader = (props: EditorHeaderProps) => {
    const {
        editedItemTitle,
        selectedBreakpointId,
        breakpoints,
        disableSaveButton,
        onBreakpointSelected,
        onSaveClick,
        onCancelClick,
        showEditorPreview,
        onChangeEditorPreviewClick,
    } = props

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white', height: '72px', justifyContent: 'center' }}>
            <Toolbar>
                <img
                    src={process.env.PUBLIC_URL + '/qubiduLogo.svg'}
                    style={{ maxWidth: 120, paddingRight: '24px' }}
                    alt={'Qubidu'}
                    loading="lazy"
                />
                <Grid pl={2}>
                    <Typography variant={'h6'} color={'#000'}>
                        {editedItemTitle}
                    </Typography>
                </Grid>
                <div style={{ flexGrow: 1 }}></div>
                <Stack direction={'row'} spacing={2}>
                    <FormControlLabel
                        style={{ color: 'black' }}
                        control={<Switch checked={showEditorPreview} onChange={() => onChangeEditorPreviewClick()} />}
                        label="Preview"
                    />
                    <ToggleButtonGroup
                        size="small"
                        value={selectedBreakpointId}
                        exclusive={true}
                        onChange={(event: React.MouseEvent<HTMLElement>, bpid: string) => {
                            const bp = breakpoints.find((bp) => bp.identifier === bpid)
                            if (bp) onBreakpointSelected(bp)
                        }}
                    >
                        {breakpoints.map((bp: Breakpoint) => (
                            <ToggleButton value={bp.identifier} key={bp.identifier}>
                                {bp.name}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    <Button variant="contained" color={'secondary'} onClick={() => onCancelClick()}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={() => onSaveClick()} disabled={disableSaveButton}>
                        Save
                    </Button>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}

export default EditorHeader
