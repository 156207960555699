import { FiberManualRecord, VisibilityOff } from '@mui/icons-material'
import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { PublishItem } from 'graphql/types'
import { useState } from 'react'
import colors from 'utils/colors'
import { UnpublishedItem, UnpublishedItemState, UnpublishedItemType } from 'utils/types'
export interface AssetDetailsPanelProps {
    unpublishedItems: UnpublishedItem[]
    onCancel: () => void
    onSubmit: (publishItems: PublishItem[]) => void
}

const renderPublishItem = (
    unpublishItem: UnpublishedItem,
    selectedUnpublishedItem: UnpublishedItem[],
    unpublishItems: UnpublishedItem[],
    onToggle: (value: UnpublishedItem) => void,
): JSX.Element => {
    const findColor = (): string => {
        let color: string = colors.black
        if (unpublishItem.state === UnpublishedItemState.EDIT) color = colors.orange
        if (unpublishItem.state === UnpublishedItemState.UNPUBLISHED) color = colors.red
        return color
    }

    const isSelected = selectedUnpublishedItem.find((item) => item.id === unpublishItem.id) !== undefined
    let isDisabled = false
    if (unpublishItem.type === UnpublishedItemType.PAGE) {
        isDisabled =
            unpublishItem.parentPageId !== undefined &&
            unpublishItem.parentPageId !== null &&
            unpublishItem.parentPageId !== '' &&
            unpublishItems.find((item) => item.id === unpublishItem.parentPageId) !== undefined &&
            selectedUnpublishedItem.find((item) => item.id === unpublishItem.parentPageId) === undefined
    }

    return (
        <Grid
            key={unpublishItem.id}
            item
            container
            direction="column"
            position="relative"
            mt={2}
            pb={2}
            style={{ borderBottom: '1px solid #ccc' }}
        >
            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={isDisabled}
                            checked={isSelected}
                            onClick={() => {
                                onToggle(unpublishItem)
                            }}
                        />
                    }
                    label={unpublishItem.name}
                />
            </Grid>
            <Grid item>{unpublishItem.url && <Typography variant={'body2'}>{unpublishItem.url}</Typography>}</Grid>
            {unpublishItem.state === UnpublishedItemState.EDIT ||
                (unpublishItem.state === UnpublishedItemState.UNPUBLISHED && (
                    <FiberManualRecord
                        style={{ color: findColor(), position: 'absolute', right: 6, top: 6, height: 8, width: 8 }}
                    />
                ))}
            {unpublishItem.state === UnpublishedItemState.DEACTIVATED && (
                <VisibilityOff style={{ position: 'absolute', right: 4, top: 3, height: 12, width: 12 }} />
            )}
        </Grid>
    )
}

const PublishPanel = (props: AssetDetailsPanelProps): JSX.Element => {
    const { unpublishedItems, onCancel, onSubmit } = props

    const [selectedUnpublishedItem, setSeletecUnpublishedItem] = useState<UnpublishedItem[]>([])

    const onToggle = (value: UnpublishedItem): void => {
        let newSelectedUnpublishedItem = [...selectedUnpublishedItem]
        const index = newSelectedUnpublishedItem.findIndex((i) => i.id === value.id)
        if (index === -1) {
            newSelectedUnpublishedItem.push(value)
        } else {
            const idsToDelete = [value.id]
            newSelectedUnpublishedItem = newSelectedUnpublishedItem.filter((unpublishItem: UnpublishedItem) => {
                for (const itemID of idsToDelete) {
                    if (
                        itemID === unpublishItem.id ||
                        (unpublishItem.parentPageId && unpublishItem.parentPageId === itemID)
                    ) {
                        idsToDelete.push(unpublishItem.id)
                        return false
                    }
                }
                return true
            })
        }
        setSeletecUnpublishedItem(newSelectedUnpublishedItem)
    }

    const blocksItems = unpublishedItems.filter((item: UnpublishedItem) => item.type === UnpublishedItemType.BLOCK)
    const pageItems = unpublishedItems.filter((item: UnpublishedItem) => item.type === UnpublishedItemType.PAGE)

    const onToggleAllPages = (): void => {
        let containsAllPages = true
        for (const page of pageItems) {
            if (selectedUnpublishedItem.find((item) => item.id === page.id) === undefined) {
                containsAllPages = false
                break
            }
        }
        if (containsAllPages) {
            setSeletecUnpublishedItem(
                selectedUnpublishedItem.filter((item) => {
                    return pageItems.find((page) => page.id === item.id) === undefined
                }),
            )
        } else {
            setSeletecUnpublishedItem([...selectedUnpublishedItem, ...pageItems])
        }
    }

    const containsAllPages = (): boolean => {
        for (const page of pageItems) {
            if (selectedUnpublishedItem.find((item) => item.id === page.id) === undefined) {
                return false
            }
        }
        return true
    }

    const onToggleAllBlocks = (): void => {
        let containsAllBlocks = true
        for (const block of blocksItems) {
            if (selectedUnpublishedItem.find((item) => item.id === block.id) === undefined) {
                containsAllBlocks = false
                break
            }
        }
        if (containsAllBlocks) {
            setSeletecUnpublishedItem(
                selectedUnpublishedItem.filter((item) => {
                    return blocksItems.find((block) => block.id === item.id) === undefined
                }),
            )
        } else {
            setSeletecUnpublishedItem([...selectedUnpublishedItem, ...blocksItems])
        }
    }

    const containsAllBlocks = (): boolean => {
        for (const block of blocksItems) {
            if (selectedUnpublishedItem.find((item) => item.id === block.id) === undefined) {
                return false
            }
        }
        return true
    }

    const onToggleAllPagesAndBlocks = (): void => {
        let containsAllPages = true
        const pagesItemsToSelect: UnpublishedItem[] = []
        let containsAllBlocks = true
        const blocksItemsToSelect: UnpublishedItem[] = []
        for (const page of pageItems) {
            if (selectedUnpublishedItem.find((item) => item.id === page.id) === undefined) {
                containsAllPages = false
                pagesItemsToSelect.push(page)
            }
        }
        for (const block of blocksItems) {
            if (selectedUnpublishedItem.find((item) => item.id === block.id) === undefined) {
                containsAllBlocks = false
                blocksItemsToSelect.push(block)
            }
        }
        if (containsAllPages && containsAllBlocks) {
            setSeletecUnpublishedItem([])
        } else {
            setSeletecUnpublishedItem([...selectedUnpublishedItem, ...blocksItems, ...pageItems])
        }
    }

    const onPublish = () => {
        onSubmit(
            selectedUnpublishedItem.map((item: UnpublishedItem) => {
                let itemType = 'page'
                if (item.type === UnpublishedItemType.BLOCK) itemType = 'block'
                return {
                    itemID: item.id,
                    type: itemType,
                }
            }),
        )
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Stack justifyContent={'space-between'} direction={'row'} style={{ width: '100%' }}>
                <Typography variant="h5">Publishing</Typography>
                <Tooltip
                    title={
                        containsAllPages() && containsAllBlocks()
                            ? 'Unselect all pages and blocks'
                            : 'Select all pages and blocks'
                    }
                    placement={'right'}
                >
                    <Checkbox
                        checked={containsAllPages() && containsAllBlocks()}
                        onClick={() => {
                            onToggleAllPagesAndBlocks()
                        }}
                    />
                </Tooltip>
            </Stack>
            <Grid container justifyContent={'space-between'}>
                {pageItems.length > 0 && (
                    <Grid
                        item
                        container
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        xs={12}
                    >
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={3} flex={1}>
                            <Typography variant="h6">Pages</Typography>
                            <Tooltip
                                title={containsAllPages() ? 'Unselect all pages' : 'Select all pages'}
                                placement={'right'}
                            >
                                <Checkbox checked={containsAllPages()} onClick={onToggleAllPages} />
                            </Tooltip>
                        </Box>
                        {pageItems.map((value: UnpublishedItem) =>
                            renderPublishItem(value, selectedUnpublishedItem, pageItems, onToggle),
                        )}
                    </Grid>
                )}
                {blocksItems.length > 0 && (
                    <Grid
                        item
                        container
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        xs={12}
                    >
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={3} flex={1}>
                            <Typography variant="h6">Blocks</Typography>
                            <Tooltip
                                title={containsAllBlocks() ? 'Unselect all blocks' : 'Select all blocks'}
                                placement={'right'}
                            >
                                <Checkbox checked={containsAllBlocks()} onClick={onToggleAllBlocks} />
                            </Tooltip>
                        </Box>
                        {blocksItems.map((value: UnpublishedItem) =>
                            renderPublishItem(value, selectedUnpublishedItem, blocksItems, onToggle),
                        )}
                    </Grid>
                )}
            </Grid>

            {blocksItems.length > 0 || pageItems.length > 0 ? (
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                    <Button variant="contained" color={'secondary'} onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={onPublish} disabled={selectedUnpublishedItem.length === 0}>
                        Publish Selected
                    </Button>
                </Stack>
            ) : (
                <Stack mt={3}>
                    <Typography variant="h6">No pages or blocks are unpublished.</Typography>
                </Stack>
            )}
        </Box>
    )
}

export default PublishPanel
