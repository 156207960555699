import gql from 'graphql-tag'

export const FOLDER_CREATE = gql`
    mutation folderCreate($input: FolderCreateInput!) {
        folderCreate(input: $input) {
            id
            websiteID
            name
            tags
            parentFolder
            createdAt
        }
    }
`
