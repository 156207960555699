import gql from 'graphql-tag'

export const ASSET_UPDATE = gql`
    mutation assetUpdate($input: AssetUpdateInput!) {
        assetUpdate(input: $input) {
            id
            name
            sizeBytes
            type
            url
            thumbnailUrl
            tags
            websiteID
            folder
            updatedAt
            updatedBy
            createdAt
            createdBy
        }
    }
`
