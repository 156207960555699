import { CreateNewFolder, Delete, Edit, UploadFile } from '@mui/icons-material'

import { Grid, IconButton, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import fileSize from 'filesize'
import Fuse from 'fuse.js'
import { Asset, AssetFolder, AssetType } from 'graphql/types'
import { useState } from 'react'
import { useStoreState } from 'store/hooks'
import './AssetGrid.css'

export interface AssetGridProps {
    allAssets: Asset[]
    allFolders: AssetFolder[]
    activeFolderID: string | undefined | null
    showOnlyImages?: boolean
    setActiveFolderID: (id: string | undefined | null) => void
    openCreateFolderPanel?: () => void
    openFileUploadPanel?: () => void
    onAssetActivated: (asset: Asset) => void
    onFolderDetails?: (folder: AssetFolder) => void
    onFolderDelete?: (id: string) => void
    onFileDelete?: (id: string) => void
    onAssetSelectToggled: (id: string) => void
    inDrawer?: boolean
}

type PathEntry = {
    id: string | undefined
    name: string
}

// add folder to path array and recurse up until root, add at position 1 because 0 = root
const buildPath = (array: PathEntry[], id: string, allFolders: AssetFolder[]) => {
    const folder = allFolders.find((f: AssetFolder) => f.id === id)
    if (folder) {
        array.splice(1, 0, { id, name: folder.name })
        if (folder.parentFolder) buildPath(array, folder.parentFolder, allFolders)
    }
}

/*
* search 
* sort?
- edit dialog (name, tags)
- folder create dialog
- folder edit dialog
- asset delete
- folder delete

- check that drawer works correctly
- select?

*/

enum SortCriteria {
    NAME_ASC,
    NAME_DESC,
    SIZE_ASC,
    SIZE_DESC,
}

const AssetGrid = (props: AssetGridProps): JSX.Element => {
    const {
        allAssets,
        allFolders,
        activeFolderID,
        showOnlyImages,
        setActiveFolderID,
        onAssetActivated,
        inDrawer,
        onFolderDetails,
        onFolderDelete,
        onFileDelete,
        openCreateFolderPanel,
        openFileUploadPanel,
    } = props

    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
    const [sort, setSort] = useState<SortCriteria>(SortCriteria.NAME_ASC)

    // filter all the assets and folders to show only ones inside the active folder
    let folders = allFolders.filter((folder: AssetFolder) => {
        if (activeFolderID) {
            return folder.parentFolder === activeFolderID
        } else {
            return folder.parentFolder === ''
        }
    })

    if (!activeFolderID) {
        folders.push({
            id: 'delete',
            name: '- Orphan Images -',
            parentFolder: '',
            createdAt: new Date().getTime(),
            websiteID: selectedWebsite?.id ?? '',
        })
    }

    let assets = allAssets.filter((asset: Asset) => {
        if ((showOnlyImages && asset.type === AssetType.IMAGE) || !showOnlyImages) {
            if (activeFolderID === 'delete') {
                return asset.folder !== '' && allFolders.find((f) => f.id === asset.folder) === undefined
            } else if (activeFolderID) {
                return asset.folder === activeFolderID
            } else {
                return asset.folder === ''
            }
        }
        return false
    })

    // if search, filter with fuse.js by search terms
    if (searchTerm) {
        const options = {
            threshold: 0.2,
            keys: ['name'],
        }
        const fuse = new Fuse(assets, options)
        assets = fuse.search(searchTerm).map((res) => res.item)
        const fuse2 = new Fuse(folders, options)
        folders = fuse2.search(searchTerm).map((res) => res.item)
    }

    // sort
    if (sort === SortCriteria.NAME_ASC) {
        folders.sort((f1, f2) => f1.name.localeCompare(f2.name))
        assets.sort((a1, a2) => a1.name.localeCompare(a2.name))
    } else if (sort === SortCriteria.NAME_DESC) {
        folders.sort((f1, f2) => f2.name.localeCompare(f1.name))
        assets.sort((a1, a2) => a2.name.localeCompare(a1.name))
    } else if (sort === SortCriteria.SIZE_ASC) {
        assets.sort((a1, a2) => a1.sizeBytes - a2.sizeBytes)
    } else if (sort === SortCriteria.SIZE_DESC) {
        assets.sort((a1, a2) => a2.sizeBytes - a1.sizeBytes)
    }

    // assemble path to display as dropdown
    const path: PathEntry[] = [{ id: '', name: '/' }]
    let activeFolder: AssetFolder | undefined
    if (activeFolderID) {
        buildPath(path, activeFolderID, allFolders)
        activeFolder = allFolders.find((f: AssetFolder) => f.id === activeFolderID)
    }

    return (
        <>
            <Box p={1} style={{ backgroundColor: 'white' }}>
                <Grid container alignItems={'center'} spacing={2}>
                    {!inDrawer && (
                        <Grid item xs={12} md={inDrawer ? 12 : 3}>
                            <Stack direction="row" spacing={2} alignItems={'center'}>
                                <Typography>Folder:</Typography>
                                <Select
                                    id="folder-tree"
                                    value={activeFolderID ?? ''}
                                    size={'small'}
                                    onChange={(e) => {
                                        setActiveFolderID(e.target.value)
                                    }}
                                >
                                    {path.map((pe) => {
                                        return (
                                            <MenuItem key={'k' + pe.id} value={pe.id}>
                                                {pe.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Stack>
                        </Grid>
                    )}
                    {(openCreateFolderPanel || openFileUploadPanel) && (
                        <Grid item md={3} justifyContent={'center'} container>
                            {activeFolderID !== 'delete' && openCreateFolderPanel && (
                                <Tooltip title="Create new folder" placement="bottom">
                                    <IconButton onClick={openCreateFolderPanel}>
                                        <CreateNewFolder />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {activeFolderID !== 'delete' && openFileUploadPanel && (
                                <Tooltip title="Upload Asset" placement="bottom">
                                    <IconButton onClick={openFileUploadPanel}>
                                        <UploadFile />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                    )}
                    <Grid item xs={12} md={inDrawer ? 12 : 3} justifyContent={'center'} container>
                        <TextField
                            id={'search'}
                            label={'Search'}
                            variant="filled"
                            size={'small'}
                            autoFocus
                            fullWidth
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                setSearchTerm(e.currentTarget.value)
                            }}
                        />
                    </Grid>
                    {!inDrawer && (
                        <Grid item container md={3} justifyContent={'flex-end'} alignItems={'center'}>
                            <Stack direction="row" spacing={2} alignItems={'center'}>
                                <Typography>Sort:</Typography>
                                <Select
                                    id="sort"
                                    value={sort}
                                    size={'small'}
                                    onChange={(e) => {
                                        setSort(e.target.value as SortCriteria)
                                    }}
                                >
                                    <MenuItem value={SortCriteria.NAME_ASC}>Name ascending</MenuItem>
                                    <MenuItem value={SortCriteria.NAME_DESC}>Name descending</MenuItem>
                                    <MenuItem value={SortCriteria.SIZE_ASC}>Size ascending</MenuItem>
                                    <MenuItem value={SortCriteria.SIZE_DESC}>Size descending</MenuItem>
                                </Select>
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <div className={`assetGrid${inDrawer ? ' inDrawer' : ''}`}>
                {activeFolder && (
                    <div
                        className={'assetItem folder'}
                        onClick={() => {
                            if (activeFolder) setActiveFolderID(activeFolder.parentFolder)
                        }}
                    >
                        <div
                            className="backgroundImg"
                            style={{ backgroundImage: 'url(/img/folder.png)', backgroundSize: 'cover', opacity: '0.4' }}
                        ></div>
                        <div className="fileInfo">
                            <Typography variant={'h6'}>..</Typography>
                            <Typography variant={'body2'}>up to parent</Typography>
                        </div>
                    </div>
                )}
                {folders.map((f: AssetFolder) => {
                    const folderCantBeDeleted =
                        allAssets.filter((a) => a.folder === f.id).length > 0 ||
                        allFolders.filter((f2) => f2.parentFolder === f.id).length > 0

                    return (
                        <div
                            key={f.id}
                            className={'assetItem folder'}
                            onClick={() => {
                                setActiveFolderID(f.id)
                            }}
                        >
                            <div
                                className="backgroundImg"
                                style={{ backgroundImage: 'url(/img/folder.png)', backgroundSize: 'cover' }}
                            ></div>
                            <div className="fileInfo">
                                <Typography variant={'h6'}>{f.name}</Typography>
                                {/* <Typography variant={'body2'}>xx files (yyy kb)</Typography> */}
                                {f.id !== 'delete' && onFolderDelete !== undefined && (
                                    <IconButton
                                        disabled={folderCantBeDeleted}
                                        className="deleteFolder"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            onFolderDelete(f.id)
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                )}
                                {f.id !== 'delete' && onFolderDetails !== undefined && (
                                    <IconButton
                                        className="editFolder"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            onFolderDetails(f)
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    )
                })}
                {assets.map((a: Asset) => {
                    let thumbnailURL = '/img/file.png'
                    if (a.type === AssetType.IMAGE && a.thumbnailUrl) thumbnailURL = a.thumbnailUrl
                    else if (a.type === AssetType.PDF) thumbnailURL = '/img/pdf.png'

                    return (
                        <div
                            key={a.id}
                            className={'assetItem file'}
                            onClick={() => {
                                onAssetActivated(a)
                            }}
                        >
                            <div
                                className="backgroundImg"
                                style={{ backgroundImage: `url(${thumbnailURL})`, backgroundSize: 'cover' }}
                            ></div>
                            <div className="fileInfo">
                                <Typography variant={'body1'}>{a.name}</Typography>
                                <Typography variant={'body2'}>{fileSize(a.sizeBytes)}</Typography>
                                {onFileDelete !== undefined && (
                                    <IconButton
                                        className="fileDelete"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            onFileDelete(a.id)
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default AssetGrid
