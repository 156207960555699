import gql from 'graphql-tag'

export const ASSET_DELETE = gql`
    mutation assetDelete($id: ID!) {
        assetDelete(id: $id) {
            success
            statusCode
        }
    }
`
