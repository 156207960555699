import { Box, Typography } from '@mui/material'

export interface InfoTextProps {
    label: string
}

export const InfoText = (props: InfoTextProps): JSX.Element => {
    const { label } = props
    return (
        <Box
            sx={{
                width: '100%',
                boxSizing: 'border-box',
                mt: 1,
                mb: 2,
            }}
        >
            <Typography>{label}</Typography>
        </Box>
    )
}
