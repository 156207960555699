import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { useStoreActions, useStoreState } from 'store/hooks'
import { toBase64 } from 'utils/base64'
import MultiEditPageInfo from './MultiEditPageInfo'
import MultiEditPageSelection from './MultiEditPageSelection'
import MultiEditPageValidation from './MultiEditPageValidation'

export interface Language {
    title: string
    key: string
}

export enum MultiEditDrawerState {
    SELECTION = 'selection',
    VALIDATION = 'validation',
    INFO = 'info',
}

export interface MultiPageEditPanelProps {
    onCancel: () => void
    openPageLinkSelector: () => void
}

const MultiEditPagePanel = (props: MultiPageEditPanelProps): JSX.Element => {
    const { onCancel, openPageLinkSelector } = props

    // const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const drawerState = useStoreState((state) => state.multiPageEditModel.status)
    const selectedFile = useStoreState((state) => state.multiPageEditModel.selectedFile)
    const verifyMultiEditExcelInformations = useStoreState(
        (state) => state.multiPageEditModel.verifyMultiEditExcelInformations,
    )
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)

    const resetData = useStoreActions((actions) => actions.multiPageEditModel.resetData)
    const setStatus = useStoreActions((actions) => actions.multiPageEditModel.setStatus)
    const setMessage = useStoreActions((actions) => actions.multiPageEditModel.setMessage)
    const verifyExcel = useStoreActions((actions) => actions.multiPageEditModel.verifyExcel)
    const executeMultiEdit = useStoreActions((actions) => actions.multiPageEditModel.executeMultiEdit)

    const hasChanges = verifyMultiEditExcelInformations.filter((v) => v.hasChanges == true)

    const handleCancel = () => {
        resetData()
        onCancel()
    }

    const handleInfoOk = () => {
        setStatus(MultiEditDrawerState.SELECTION)
        setMessage({
            isError: false,
            messages: [],
        })
    }

    const handleVerifyExcel = () => {
        if (selectedFile == undefined) return
        toBase64(selectedFile)
            .then((data) => {
                verifyExcel({ websiteID: selectedWebsite?.id ?? '', file: data as string })
            })
            .catch((err) => {
                console.log(err)
                setMessage({
                    isError: true,
                    messages: ["Couldn't convert file to base64"],
                })
                setStatus(MultiEditDrawerState.INFO)
            })
    }

    const handleEditPages = () => {
        executeMultiEdit({ websiteID: selectedWebsite?.id ?? '', data: verifyMultiEditExcelInformations })
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">Edit multiple pages</Typography>
            </Grid>

            {/* Render selection */}
            {drawerState == MultiEditDrawerState.SELECTION && (
                <MultiEditPageSelection openPageLinkSelector={openPageLinkSelector} />
            )}

            {/* Render validation */}
            {drawerState == MultiEditDrawerState.VALIDATION && <MultiEditPageValidation />}

            {/* Render status/error */}
            {drawerState == MultiEditDrawerState.INFO && <MultiEditPageInfo />}

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={4}>
                {drawerState == MultiEditDrawerState.SELECTION && (
                    <>
                        <Button variant="contained" color={'secondary'} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button disabled={selectedFile == undefined} variant="contained" onClick={handleVerifyExcel}>
                            Upload & Check
                        </Button>
                    </>
                )}
                {drawerState == MultiEditDrawerState.VALIDATION && (
                    <>
                        <Button variant="contained" color={'secondary'} onClick={handleInfoOk}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleEditPages} disabled={hasChanges.length == 0}>
                            Edit Pages
                        </Button>
                    </>
                )}
                {drawerState == MultiEditDrawerState.INFO && (
                    <Button variant="contained" onClick={handleInfoOk}>
                        Ok
                    </Button>
                )}
            </Stack>
        </Box>
    )
}

export default MultiEditPagePanel
