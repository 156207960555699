import { NodeModel } from '@minoru/react-dnd-treeview'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { CustomData } from 'components/PageTreeReorder//types'
import PageTreeReorder from 'components/PageTreeReorder/PageTreeReorder'
import { OrderItemInput, Page } from 'graphql/types'
import { useState } from 'react'

export interface PageSortPanelProps {
    list: Page[]
    savePageOrder: (elems: OrderItemInput[]) => void
    onCancel: () => void
}

const PageSortPanel = (props: PageSortPanelProps): JSX.Element => {
    const { savePageOrder, onCancel, list } = props
    const [treeData, setTreeData] = useState<NodeModel<CustomData>[]>([])

    const handleSave = () => {
        const finalOrder: OrderItemInput[] = []
        const recursiveTree = (parentRef: NodeModel<CustomData>, list: NodeModel<CustomData>[]) => {
            list.forEach((elem) => {
                if (elem.parent === parentRef.id) {
                    const data = elem.data
                    const parentData = parentRef.data
                    if (data && parentData) {
                        finalOrder.push({ id: data.pageID, parent: parentData.pageID })
                    }
                    recursiveTree(elem, list)
                }
            })
        }

        for (const treeEntry of treeData) {
            if (treeEntry.parent === 0) {
                const data = treeEntry.data
                if (data) {
                    finalOrder.push({ id: data.pageID })
                    recursiveTree(treeEntry, treeData)
                }
            }
        }

        savePageOrder(finalOrder)
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant="h6">Reorder pages</Typography>
            </Grid>
            <PageTreeReorder list={list} treeData={treeData} setTreeData={setTreeData} />

            <Stack mt={8} mb={3} direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSave}>
                    Save
                </Button>
            </Stack>
        </Box>
    )
}

export default PageSortPanel
