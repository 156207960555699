import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import AssetGrid from 'components/AssetGrid/AssetGrid'
import { Asset, AssetFolder } from 'graphql/types'
import { useState } from 'react'

export interface AssetSelectPanelProps {
    allAssets: Asset[]
    allFolders: AssetFolder[]
    showOnlyImages?: boolean
    onSelectAsset: (id: string) => void
    cancel: () => void
}

const AssetSelectPanel = (props: AssetSelectPanelProps): JSX.Element => {
    const { allFolders, allAssets, cancel, showOnlyImages, onSelectAsset } = props

    const [activeFolderID, setActiveFolderID] = useState<string | undefined | null>(undefined)

    const onSetActiveFolderID = (folderID: string | undefined | null) => {
        setActiveFolderID(folderID)
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Stack justifyContent={'space-between'} direction={'row'} style={{ width: '100%' }}>
                <Typography variant="h6">Select an asset</Typography>
                <IconButton aria-label="cancel" onClick={cancel}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <AssetGrid
                showOnlyImages={showOnlyImages}
                allAssets={allAssets}
                allFolders={allFolders}
                activeFolderID={activeFolderID}
                setActiveFolderID={onSetActiveFolderID}
                onAssetActivated={(a: Asset) => onSelectAsset(a.id)}
                onAssetSelectToggled={(id: string) => console.log('onFileSelectToggled', id)}
                inDrawer={true}
            />
        </Box>
    )
}

export default AssetSelectPanel
