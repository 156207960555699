import { Add, Close } from '@mui/icons-material'
import { Box, Grid, IconButton, Tooltip } from '@mui/material'
import PageTree from 'components/PageTree'
import { PageTreeEntry, Status } from 'components/PageTree/PageTree'
import { Block, State } from 'graphql/types'

export interface BlockTreePanelProps {
    blocks: Block[]
    selectedBlock: string
    onSelectBlock: (id: string) => void
    cancel?: () => void
    onBlockCreate?: () => void
}

const getStatus = (block: Block): Status => {
    if (block.currentVersion.state === State.UNPUBLISHED) {
        return Status.NOT_PUBLISHED
        //TODO: get version history from the page tree load. Maybe load all infos from the page? So we don't need pageLoad?
        // if(true){
        //     return Status.NOT_PUBLISHED
        // }else{
        //     return Status.PUBLISHED_WITH_CHANGES
        // }
    } else if (block.currentVersion.state === State.PUBLISHED) {
        return Status.PUBLISHED_NO_CHANGES
    }

    return Status.PUBLISHED_NO_CHANGES
}

const convertBlockListToPageTree = (blockList: Block[]): PageTreeEntry[] => {
    const pageTree: PageTreeEntry[] = []

    blockList.forEach((block: Block) => {
        const pageTreeEntry: PageTreeEntry = {
            id: block.id,
            name: block.currentVersion.name,
            status: getStatus(block),
            subpages: [],
        }
        pageTree.push(pageTreeEntry)
    })
    return pageTree
}

const BlockTreePanel = (props: BlockTreePanelProps): JSX.Element => {
    const { blocks, selectedBlock, onBlockCreate, onSelectBlock, cancel } = props

    return (
        <Box display="flex" flexDirection="column" flex={1} height="100%">
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {cancel && (
                    <IconButton aria-label="cancel" onClick={cancel}>
                        <Close />
                    </IconButton>
                )}
            </Grid>
            <PageTree
                pages={convertBlockListToPageTree(blocks)}
                onPageSelect={onSelectBlock}
                selectedPage={selectedBlock}
                expanded={[]}
                onSetExpanded={() => {
                    /*nothing here*/
                }}
            />
            <Box marginTop="auto" display="flex" marginLeft="auto">
                {onBlockCreate !== undefined && (
                    <Tooltip title="Create new block">
                        <IconButton onClick={onBlockCreate}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Box>
    )
}

export default BlockTreePanel
