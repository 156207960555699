import gql from 'graphql-tag'

export const BLOCK_DELETE = gql`
    mutation blockDelete($ids: [String!]!) {
        blockDelete(ids: $ids) {
            success
            statusCode
        }
    }
`
