import { Button, Grid, Typography } from '@mui/material'
import React from 'react'

const NotLoggedIn = (): JSX.Element => {
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography>Please login first</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={() => alert('not implemented')}>
                    Go to Login
                </Button>
            </Grid>
        </Grid>
    )
}

export default NotLoggedIn
