// import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@mui/material'
import ErrorDialog from 'components/ErrorDialog'
import GeneralSnackbar, { SnackbarType } from 'components/GeneralSnackbar/GeneralSnackbar'
import AssetManagerPage from 'pages/AssetManagerPage/AssetManagerPage'
import BlockListPage from 'pages/BlockListPage/BlockListPage'
import NotLoggedIn from 'pages/NotLoggedIn/NotLoggedIn'
import PageEditPage from 'pages/PageEditPage/PageEditPage'
import PageTreePage from 'pages/PageTreePage/PageTreePage'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'
import 'utils/i18n'
import { NavigationRoutes } from 'utils/navigationRoutes'
import { ModifiedTheme } from 'utils/theme'
import useVersionTitle from 'utils/useVersionTitle'

function App(): JSX.Element {
    useVersionTitle()
    const serverError = useStoreState((state) => state.model.serverError)
    const generalSnackbarConfig = useStoreState((state) => state.model.generalSnackbarConfig)
    const configureSnackbar = useStoreActions((actions) => actions.model.configureSnackbar)
    const configureServerError = useStoreActions((actions) => actions.model.configureServerError)

    return (
        <>
            <HashRouter>
                <ThemeProvider theme={ModifiedTheme}>
                    {serverError && (
                        <ErrorDialog
                            errorConfig={serverError}
                            onClick={() => {
                                configureServerError(undefined)
                                window.location.reload()
                            }}
                        />
                    )}
                    <GeneralSnackbar
                        message={generalSnackbarConfig.message}
                        isOpen={generalSnackbarConfig.isOpen}
                        type={generalSnackbarConfig.type}
                        onClose={() => {
                            configureSnackbar({ isOpen: false, message: '', type: SnackbarType.INFO })
                        }}
                    />
                    <Routes>
                        <Route path={NavigationRoutes.PAGE_TREE} element={<PageTreePage />} />
                        <Route path={NavigationRoutes.BLOCK_LIST} element={<BlockListPage />} />
                        <Route path={NavigationRoutes.PAGE_EDIT} element={<PageEditPage />} />
                        <Route path={NavigationRoutes.ASSETS} element={<AssetManagerPage />} />
                        <Route path={NavigationRoutes.NOT_LOGGED_IN} element={<NotLoggedIn />} />
                        <Route path={'/'} element={<PageTreePage />} />
                    </Routes>
                </ThemeProvider>
            </HashRouter>
        </>
    )
}

export default App
