import gql from 'graphql-tag'

export const ASSET_UPLOAD = gql`
    mutation assetUpload($input: AssetUploadInput!) {
        assetUpload(input: $input) {
            id
            name
            sizeBytes
            type
            url
            thumbnailUrl
            tags
            websiteID
            folder
            updatedAt
            updatedBy
            createdAt
            createdBy
        }
    }
`
