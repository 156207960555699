import { Block, Page } from 'graphql/types'
import { PageElement } from 'utils/types'

export const getParsedPageElements = (item?: Page | Block): PageElement[] => {
    let currentElements: PageElement[] = []
    if (item && item.currentVersion.contents.trim().length > 0) {
        currentElements = JSON.parse(item.currentVersion.contents)
    }

    return currentElements
}
