import { Box, Divider, Typography } from '@mui/material'
import SettingsPanel from 'drawerPanels/SettingsPanel/SettingsPanel'
import colors from 'utils/colors'

export enum SettingsSelectionPanelNavigation {
    WEBSITE,
    ELEMENT_DEFINITIONS,
    DATA_MODULES,
}

export interface SettingsSelectionPanelProps {
    navigation: SettingsSelectionPanelNavigation | undefined
    setNavigation: (navigation: SettingsSelectionPanelNavigation | undefined) => void
}

const SettingsSelectionPanel = (props: SettingsSelectionPanelProps): JSX.Element => {
    const { navigation, setNavigation } = props

    return (
        <Box display="flex" flexDirection="column" flex={1}>
            {navigation === undefined && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            borderRadius: 0,
                            height: 60,
                            cursor: 'pointer',
                            ':hover': { backgroundColor: colors.primaryHalfOpacity },
                            bgcolor: colors.secondary,
                            justifyContent: 'center',
                            alignItems: 'center',
                            transition: 'background-color 0.25s ease',
                        }}
                        onClick={() => setNavigation(SettingsSelectionPanelNavigation.WEBSITE)}
                    >
                        <Typography variant="body2">{'Website Settings'.toUpperCase()}</Typography>
                    </Box>
                    <Divider color={colors.midGray} />
                </>
            )}
            {navigation === SettingsSelectionPanelNavigation.WEBSITE && (
                <SettingsPanel
                    goBack={() => {
                        setNavigation(undefined)
                    }}
                />
            )}
        </Box>
    )
}

export default SettingsSelectionPanel
