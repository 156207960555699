import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material'
import { Asset, Page } from 'graphql/types'
import React, { useState } from 'react'
import { FileInfo, resolveFileData, resolvePagelURL } from 'utils/linkResolver'
import { FieldLocator, LinkData } from 'utils/types'

export interface LinkControlProps {
    label: string
    valueName: string
    value: LinkData
    disableInternal?: boolean
    disableExternal?: boolean
    disableFile?: boolean
    pageList: Page[]
    assets: Asset[]
    onValueChange: (valueName: string, newValue: LinkData) => void
    openPageLinkSelector: (fieldLocator: FieldLocator) => void
    openFileLinkSelector: (fieldLocator: FieldLocator) => void
}

enum LinkSelectorTabs {
    Internal = 'internal',
    External = 'external',
    File = 'file',
}

const getDefaultSelectedTab = (value: LinkData): LinkSelectorTabs => {
    let selectedTab: LinkSelectorTabs = LinkSelectorTabs.Internal

    if (value.__qbd_pageId) {
        selectedTab = LinkSelectorTabs.Internal
    } else if (value.__qbd_fileId) {
        selectedTab = LinkSelectorTabs.File
    }

    return selectedTab
}

export const LinkControl = (props: LinkControlProps): JSX.Element => {
    const {
        valueName,
        value,
        onValueChange,
        disableExternal,
        disableFile,
        disableInternal,
        label,
        pageList,
        assets,
        openFileLinkSelector,
        openPageLinkSelector,
    } = props

    const [tab, setTab] = useState<LinkSelectorTabs>(getDefaultSelectedTab(value))

    const numberOfActiveTabs = [disableExternal, disableInternal, disableFile].filter((x) => x).length

    let fileInfo: FileInfo | null | undefined
    if (value.__qbd_fileId) {
        fileInfo = resolveFileData(value.__qbd_fileId, assets)
    }

    const control = (
        <Switch
            checked={value.newWindow ? true : false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onValueChange(valueName, { ...value, newWindow: e.target.checked })
            }}
        />
    )

    return (
        <Box
            sx={{
                width: '100%',
                boxSizing: 'border-box',
                mt: 1,
                mb: 2,
                '& .MuiTextField-root': { width: '100%' },
            }}
        >
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography>{label}</Typography>
                {!(numberOfActiveTabs > 1) && (
                    <ToggleButtonGroup
                        color="primary"
                        value={tab}
                        exclusive
                        size="small"
                        onChange={(event: React.MouseEvent<HTMLElement>, value: any) => {
                            if (value !== null) setTab(value)
                        }}
                        style={{ marginBottom: '16px' }}
                    >
                        {!disableInternal && (
                            <ToggleButton value="internal" disabled={disableInternal}>
                                Page
                            </ToggleButton>
                        )}
                        {!disableExternal && (
                            <ToggleButton value="external" disabled={disableExternal}>
                                URL
                            </ToggleButton>
                        )}
                        {!disableFile && (
                            <ToggleButton value="file" disabled={disableFile}>
                                File
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                )}
            </Grid>

            {tab === LinkSelectorTabs.External && (
                <TextField
                    id={valueName + '-ex'}
                    label={'Externe URL'}
                    multiline
                    value={value.url}
                    variant="filled"
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        onValueChange(valueName, {
                            ...value,
                            url: e.currentTarget.value,
                            __qbd_pageId: undefined,
                            __qbd_fileId: undefined,
                        })
                    }}
                />
            )}
            {tab === LinkSelectorTabs.Internal && (
                <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Interne Seite:
                        </Typography>
                        <Typography variant="body2">
                            <span style={{ overflowWrap: 'break-word' }}>
                                {value.__qbd_pageId
                                    ? resolvePagelURL(value.__qbd_pageId, pageList)
                                    : 'Keine Seite ausgewählt'}
                            </span>
                        </Typography>
                    </CardContent>
                    <CardActions style={{ textAlign: 'right' }}>
                        <Button
                            size="small"
                            onClick={() => {
                                openPageLinkSelector({
                                    fieldName: valueName,
                                })
                            }}
                        >
                            Seite wählen
                        </Button>
                    </CardActions>
                </Card>
            )}
            {tab === LinkSelectorTabs.File && (
                <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Datei:
                        </Typography>
                        {fileInfo && (
                            <Typography variant="body2">
                                {fileInfo.name} ({fileInfo.size}kb)
                            </Typography>
                        )}
                        {!fileInfo && <Typography variant="body2">Keine Datei ausgewählt</Typography>}
                    </CardContent>
                    <CardActions>
                        <Button
                            size="small"
                            onClick={() =>
                                openFileLinkSelector({
                                    fieldName: valueName,
                                })
                            }
                        >
                            Datei wählen
                        </Button>
                    </CardActions>
                </Card>
            )}

            <FormControlLabel control={control} label={'In neuem Fenster öffnen'} />
        </Box>
    )
}
