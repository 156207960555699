import { Delete } from '@mui/icons-material'
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material'
import { Asset } from 'graphql/types'
import { FileInfo, resolveFileData } from 'utils/linkResolver'
import { FieldLocator, MediaData } from 'utils/types'

export interface MediaControlProps {
    label: string
    valueName: string
    value: MediaData
    assets: Asset[]
    openMediaSelector: (fieldLocator: FieldLocator) => void
    deleteMediaSelection: (fieldLocator: FieldLocator) => void
    onValueChange: (valueName: string, newValue: MediaData) => void
}

export const MediaControl = (props: MediaControlProps): JSX.Element => {
    const { valueName, value, label, openMediaSelector, deleteMediaSelection, assets, onValueChange } = props
    let fileInfo: FileInfo | null | undefined
    if (value.__qbd_fileId) {
        fileInfo = resolveFileData(value.__qbd_fileId, assets)
    }

    return (
        <Box
            sx={{
                width: '100%',
                boxSizing: 'border-box',
                mt: 1,
                mb: 2,
                '& .MuiTextField-root': { width: '100%' },
            }}
        >
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography>{label}</Typography>
            </Grid>
            <Card>
                {fileInfo && (
                    <CardMedia
                        onClick={() =>
                            openMediaSelector({
                                fieldName: valueName,
                            })
                        }
                        component="img"
                        image={fileInfo.thumbnailUrl}
                        style={{ cursor: 'pointer' }}
                    />
                )}
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Media:
                    </Typography>
                    {fileInfo && (
                        <Typography variant="body2">
                            {fileInfo.name} ({fileInfo.size}kb)
                        </Typography>
                    )}
                    {!fileInfo && <Typography variant="body2">Keine Media ausgewählt</Typography>}
                    <Box
                        sx={{
                            width: '100%',
                            boxSizing: 'border-box',
                            mt: 1,
                            mb: 2,
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                    >
                        <TextField
                            label={'Alt Text'}
                            value={value.__qbd_altText}
                            variant={'standard'}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                onValueChange(valueName, { ...value, __qbd_altText: e.currentTarget.value })
                            }}
                        />
                    </Box>
                </CardContent>
                <CardActions>
                    <Button
                        size="small"
                        onClick={() =>
                            openMediaSelector({
                                fieldName: valueName,
                            })
                        }
                    >
                        Media wählen
                    </Button>
                    <IconButton
                        aria-label="remove media"
                        onClick={() => deleteMediaSelection({ fieldName: valueName })}
                    >
                        <Delete />
                    </IconButton>
                </CardActions>
            </Card>
        </Box>
    )
}
