import gql from 'graphql-tag'

export const BLOCK_SAVE = gql`
    mutation blockSave($input: BlockSaveInput!) {
        blockSave(input: $input) {
            id
            identifier
            createdAt
            deletedAt
            currentVersion {
                id
                name
                state
                contents
                updatedBy {
                    id
                    name
                }
                createdAt
                updatedAt
            }
            historyVersions {
                name
                id
                blockID
                state
                contents
                createdAt
                updatedAt
                updatedBy {
                    id
                    name
                }
            }
        }
    }
`
