import FiberManualRecord from '@mui/icons-material/FiberManualRecord'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Box, Checkbox, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
    TreeItem2Content,
    TreeItem2GroupTransition,
    TreeItem2IconContainer,
    TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2'
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon'
import { unstable_useTreeItem2 as useTreeItem, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2'
import clsx from 'clsx'
import React from 'react'
import { default as colors } from 'utils/colors'
import { Status } from './PageTree'

declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string
        '--tree-view-bg-color'?: string
    }
}

interface StyledTreeItemProps
    extends Omit<UseTreeItem2Parameters, 'rootRef'>,
        Omit<React.HTMLAttributes<HTMLLIElement>, 'children'> {
    itemStatus: string
    multiSelect?: boolean
}

const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(2),
}))

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    marginBottom: theme.spacing(0.3),
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.expanded': {
        fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.selected': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.primary.light})`,
        color: 'var(--tree-view-color)',
    },
}))

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
    marginRight: theme.spacing(1),
}))

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(({ theme }) => ({
    marginLeft: 0,
    [`& .content`]: {
        paddingLeft: theme.spacing(2),
    },
}))

const findColor = (status: string): string => {
    let color: string = colors.black
    if (status === Status.PUBLISHED_WITH_CHANGES) color = colors.orange
    if (status === Status.NOT_PUBLISHED) color = colors.red
    return color
}

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
    props: StyledTreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const { id, itemId, label, itemStatus, multiSelect, disabled, children, ...other } = props

    const { getRootProps, getContentProps, getIconContainerProps, getLabelProps, getGroupTransitionProps, status } =
        useTreeItem({ id, itemId, children, label, disabled, rootRef: ref })

    return (
        <CustomTreeItemRoot {...getRootProps({ ...other })}>
            <CustomTreeItemContent
                {...getContentProps({
                    className: clsx('content', {
                        expanded: status.expanded,
                        selected: status.selected,
                        focused: status.focused,
                    }),
                })}
            >
                <CustomTreeItemIconContainer {...getIconContainerProps()}>
                    <TreeItem2Icon status={status} />
                </CustomTreeItemIconContainer>
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        alignItems: 'center',
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    {multiSelect && <Checkbox checked={status.selected} className="black" />}
                    <Typography
                        {...getLabelProps({
                            variant: 'body2',
                            sx: { display: 'flex', fontWeight: 'inherit', flexGrow: 1 },
                        })}
                    />
                    {itemStatus !== Status.PUBLISHED_NO_CHANGES &&
                        itemStatus !== Status.DEACTIVATED &&
                        itemStatus !== Status.IS_TEMPLATE && (
                            <FiberManualRecord
                                style={{
                                    color: findColor(itemStatus),

                                    height: 8,
                                    width: 8,
                                }}
                            />
                        )}
                    {itemStatus === Status.IS_TEMPLATE && <Typography color={colors.blue}>T</Typography>}
                    {itemStatus === Status.DEACTIVATED && <VisibilityOff style={{ height: 12, width: 12 }} />}
                </Box>
            </CustomTreeItemContent>
            {children && <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />}
        </CustomTreeItemRoot>
    )
})

export default CustomTreeItem
